/**
 * External dependencies
 */
import { useReducer, useMemo } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { exposedActions, internalActions } from './actions';
import reducer from './reducer';
import { INITIAL_STATE } from '../constants';
import type { SpellCheckReducer } from '../types';

const wrapWithDispatch = (actionFnOrActionObject, dispatch) => {
  if (actionFnOrActionObject instanceof Function) {
    return actionFnOrActionObject(dispatch);
  }

  const actions = actionFnOrActionObject;
  return Object.keys(actions).reduce(
    (collection, action) => ({
      ...collection,
      [action]: wrapWithDispatch(actions[action], dispatch),
    }),
    {}
  );
};

function useSpellCheckReducer(): SpellCheckReducer {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const wrappedExposedActions = useMemo(
    () => wrapWithDispatch(exposedActions, dispatch),
    [exposedActions]
  );

  const wrappedInternalActions = useMemo(
    () => wrapWithDispatch(internalActions, dispatch),
    [internalActions]
  );

  return {
    state,
    actions: {
      api: wrappedExposedActions,
      internal: wrappedInternalActions,
    },
  };
}

export default useSpellCheckReducer;
