import { useEffect, useState, useCallback } from '@web-stories-wp/react';
import { useStory } from '@web-stories-wp/story-editor/src/app/story';
import { createPage } from '@web-stories-wp/story-editor/src/elements';
import useInsertElement from '@web-stories-wp/story-editor/src/components/canvas/useInsertElement';
import { useStudioContext } from '@hooks/studioContext';
import { PageType } from '@src/enums/pageType';

function createResourceFromPage(page) {
  const isVideo = page.assetType === 'video';

  const videoData = {};

  if (isVideo) {
    videoData.isMuted = false;
    videoData.length = page.duration;
    videoData.isOptimized = true;
  }

  return {
    type: page.assetType,
    src: page.url,
    width: page.width,
    height: page.height,
    alt: 'original',
    local: false,
    isExternal: true,
    mimeType: page.mimeType,
    poster: page.playcardUrl,
    ...videoData,
  };
}

function getPageByOrder(pages, pageOrder) {
  return pages.find(
    (x) =>
      x.cmsPageId === pageOrder.id ||
      (pageOrder.studioPageId != null && x.id === pageOrder.studioPageId)
  );
}

const usePageImport = () => {
  const studioContext = useStudioContext();
  const insertElement = useInsertElement();
  const [pagesImported, setPagesImported] = useState();
  const [pagesOrdered, setPagesOrdered] = useState();
  const [existingPages, setExistingPages] = useState();
  const [loadedPages, setLoadedPages] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const {
    pagesToImport,
    pagesOrder,
    addPage,
    addPageAt,
    setBackgroundElement,
    updateStory,
    pages,
    deletePage,
    arrangePage,
    setCurrentPage,
  } = useStory(
    ({
      state: { story, currentPage, pages },
      actions: {
        addPage,
        addPageAt,
        setBackgroundElement,
        updateStory,
        deletePage,
        arrangePage,
        setCurrentPage,
      },
    }) => {
      return {
        pagesToImport: story.pagesToImport,
        pagesOrder: story.pagesOrder,
        currentPage,
        addPage,
        addPageAt,
        setBackgroundElement,
        updateStory,
        pages,
        deletePage,
        arrangePage,
        setCurrentPage,
      };
    }
  );

  useEffect(() => {
    if (!pages || !pages.length || existingPages) return;

    let resultPages = [...pages];
    let pagesToRemove = [];

    if (pagesOrder) {
      const existingStudioPagesIds = pagesOrder.map((x) => x.studioPageId);
      pagesToRemove = pages.filter(
        (x) => !existingStudioPagesIds.includes(x.id)
      );

      resultPages = pages.filter((x) => existingStudioPagesIds.includes(x.id));
    }

    let blankPage;
    if (resultPages.length === 0) {
      //There are no pages on CMS Story, so we need to remove all Studio Pages (there is nothing to import)
      //We always need to have at least one page on a Studio Story definition
      //So first we create new blank page and them remove all existing pages
      blankPage = { ...createPage(), isDefaultBlankPage: true };
      resultPages.push(blankPage);
    }

    setExistingPages(resultPages);

    if (blankPage) {
      addPageAt({ page: blankPage });
    }

    pagesToRemove.forEach((x) => deletePage({ pageId: x.id }));
  }, [
    pages,
    existingPages,
    setExistingPages,
    deletePage,
    pagesOrder,
    addPageAt,
  ]);

  const createPageWithResource = useCallback(
    (page) => {
      const newPage = createPage();
      newPage.cmsPageId = page.id;
      newPage.cmsPageType = page.type;
      newPage.cmsPageQuestionId = page.questionId;
      newPage.readonly = newPage.readonly =
        page.type === PageType.Poll ||
        page.type === PageType.TriviaQuiz ||
        page.isProcessing;
      newPage.engagementUnitTitle = page.engagementUnitTitle;
      newPage.isProcessing = page.isProcessing;

      if (page.isProcessing) {
        newPage.backgroundColor = { color: { r: 24, g: 26, b: 26 } };
      }

      addPage({ page: newPage });

      if (!page.isProcessing) {
        var resource = createResourceFromPage(page);

        const element = insertElement(resource.type, {
          resource,
          isInitBackground: true,
        });

        setBackgroundElement({ elementId: element.id });
      }
    },
    [addPage, insertElement, setBackgroundElement]
  );

  useEffect(() => {
    const importPages = async () => {
      if (pagesImported || !existingPages || !pagesToImport) return;

      if (pagesToImport.length === 0) {
        setIsLoading(false);
        setPagesImported(true);
      }

      setIsLoading(true);

      const processingPages = [];

      for (let i = 0; i < pagesToImport.length; i++) {
        const page = pagesToImport[i];
        const existingPage = existingPages.find((x) => x.cmsPageId === page.id);
        if (existingPage) {
          continue;
        }

        createPageWithResource(page);
      }

      const defaultBlankPage = existingPages.find((x) => x.isDefaultBlankPage);

      if (defaultBlankPage) {
        const pageId = defaultBlankPage.id;
        deletePage({ pageId });
      }

      setPagesImported(true);
      updateStory({
        properties: { pagesToImport: [], processingPages: processingPages },
      });
    };

    importPages();
  }, [
    pagesToImport,
    existingPages,
    insertElement,
    updateStory,
    addPage,
    setBackgroundElement,
    deletePage,
    setPagesImported,
    pagesImported,
    createPageWithResource,
  ]);

  useEffect(() => {
    if (
      pagesOrdered ||
      (pagesToImport && pagesToImport.length) ||
      !pagesImported ||
      !pages ||
      !pagesOrder
    ) {
      return;
    }

    if (!pagesOrder.length) {
      setPagesOrdered(true);
      return;
    }

    for (let pageOrder of pagesOrder) {
      const page = pages.find(
        (x) =>
          x.cmsPageId === pageOrder.id ||
          (pageOrder.studioPageId != null && x.id === pageOrder.studioPageId)
      );

      if (!page) {
        continue;
      }

      arrangePage({ pageId: page.id, position: pageOrder.order });
    }

    let pageToSelect;

    if (studioContext && studioContext.cmsPageId) {
      const pageOrder = pagesOrder.find(
        (x) => x.id === studioContext.cmsPageId
      );
      pageToSelect = getPageByOrder(pages, pageOrder);
    } else {
      pageToSelect = getPageByOrder(pages, pagesOrder[0]);
    }

    if (pageToSelect) {
      setCurrentPage({ pageId: pageToSelect.id });
    }

    setPagesOrdered(true);
  }, [
    pagesImported,
    pagesOrder,
    pagesToImport,
    pages,
    arrangePage,
    setCurrentPage,
    pagesOrdered,
    setPagesOrdered,
    studioContext,
  ]);

  useEffect(() => {
    if (!pagesImported || !pages || !pagesOrdered || loadedPages) {
      if (loadedPages) {
        setIsLoading(false);
      }
      return;
    }

    setLoadedPages([...pages]);
    updateStory({ properties: { loadedPages: [...pages] } });
    setIsLoading(false);

    if (studioContext.addNewPage && pages.length) {
      const newPage = createPage();
      addPage({ page: newPage });
      arrangePage({ pageId: newPage.id, position: pages.length });
    }
  }, [
    loadedPages,
    pagesImported,
    pages,
    pagesOrdered,
    setLoadedPages,
    updateStory,
    setIsLoading,
    addPage,
    studioContext,
    setCurrentPage,
    arrangePage,
  ]);

  return isLoading;
};

export default usePageImport;
