/**
 * External dependencies
 */
import { createContext } from '@web-stories-wp/react';
import { INITIAL_STATE } from './constants';
import type { SpellCheckContext } from './types';

export default createContext<SpellCheckContext>({
  actions: {
    acceptSuggestion: () => INITIAL_STATE,
    dismissSuggestion: () => INITIAL_STATE,
  },
  state: { ...INITIAL_STATE, isLoading: false },
});
