import * as React from 'react';
import styled from 'styled-components';

const ThumbnailContainer = styled.div`
  position: relative;
  display: inline-block;
  min-height: 0;
  width: 100%;
  padding-top: ${({ assetRatio }) => {
    if (parseInt(assetRatio) < 100) {
      return assetRatio;
    } else {
      return '56.25%';
    }
  }};
  background: ${({ theme }) => theme.colors.interactiveBg.disable};
  border-radius: ${({ theme }) => theme.borders.radius.small};
  overflow: hidden;

  img,
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ assetRatio }) => {
      if (parseInt(assetRatio) >= 100) {
        return 'height: 100%; width: auto;';
      } else {
        return 'width: 100%; height: auto;';
      }
    }};
  }
`;

function ExternalMediaThumbnail({
  assetRatio,
  children,
}: {
  assetRatio: string;
  children: React.ReactNode;
}) {
  return (
    <ThumbnailContainer assetRatio={assetRatio}>{children}</ThumbnailContainer>
  );
}

export default ExternalMediaThumbnail;
