import { useMemo } from 'react';
import {
  authContext,
  deleteContext,
  refreshTokenUrl,
} from '@services/authorization';

const useAuthorizationManager = () => {
  const initializedAuthContext = useMemo(() => {
    authContext.initialize(process.env.CMS_URL);
    return authContext;
  }, []);

  return { authContext: initializedAuthContext, deleteContext };
};

export { refreshTokenUrl, useAuthorizationManager };
