/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import styled from 'styled-components';
/**
 * Internal dependencies
 */
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from '../button';
import * as Icons from '../../icons';
import { THEME_CONSTANTS } from '../../theme';
import { Modal } from '../modal';
import { Headline } from '../typography';

const DialogBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 512px;
  max-height: 100%;
  color: ${({ theme }) => theme.colors.fg.primary};
  background-color: ${({ theme }) => theme.colors.bg.primary};
  overflow-y: auto;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.borders.radius.medium};
  border: ${({ theme }) => `1px solid ${theme.colors.divider.primary}`};
`;

const DialogContent = styled.div`
  padding: ${({ withCloseButton }) =>
    withCloseButton ? '8px 20px 12px' : '8px 0 12px'};
  margin: 0;
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.fg.primary};
`;

const DialogActions = styled.div`
  display: flex;
  margin: 0;

  ${({ stacked }) =>
    stacked
      ? `
      width: 100%;
      flex-direction: column-reverse;
      gap: 10px;
      
      & > button,
      & > a {
        flex: 1;
      }`
      : `align-self: flex-end;
      
      & > button,
      & > a {
        margin-right: 10px;
        margin-left: 6px;
      
        &:last-child {
          margin-right: 0;
        }
      }
    `}
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export type BaseDialogProps = {
  children: React.ReactNode;
  // When true, dialog will be visible
  isOpen: boolean;
  // Action taken on 'x'/clicking outside of dialog. Default secondary action.
  onClose: () => void;
  title?: string;
  contentLabel?: string;
  stackedActions?: boolean;
  withCloseButton?: boolean;
};

type DialogProps = BaseDialogProps & {
  actions: React.ReactNode | React.ReactNode[];
};

/**
 * Dialogs should be wrapped in a ThemeProvider
 * and given the inverted theme to the app.
 */
export function Dialog({
  children,
  title,
  actions = [],
  isOpen = false,
  onClose,
  contentLabel,
  stackedActions = false,
  withCloseButton = false,
  ...rest
}: DialogProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel={contentLabel || title}
      {...rest}
    >
      <DialogBox>
        {Boolean(title) && (
          <Headline
            as="h2"
            size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.X_SMALL}
          >
            {title}
          </Headline>
        )}
        <DialogContent withCloseButton={withCloseButton}>
          {withCloseButton && (
            <CloseButton
              onClick={onClose}
              size={BUTTON_SIZES.SMALL}
              variant={BUTTON_VARIANTS.SQUARE}
            >
              <Icons.Cross />
            </CloseButton>
          )}
          {children}
        </DialogContent>
        {Boolean(actions) && (
          <DialogActions stacked={stackedActions}>{actions}</DialogActions>
        )}
      </DialogBox>
    </Modal>
  );
}
