import { getSavedDismissedSuggestions } from '../../helpers/dismissedSuggestions';
import { getUniqueSuggestionId } from '../../helpers/suggestionId';
import type { SpellCheckState } from '../../types';
import type { UpdateSuggestionsPayload } from './types';

function updateSuggestions(
  state: SpellCheckState,
  {
    allTextElements,
    suggestions: newSuggestions,
    updatedTextElements,
  }: UpdateSuggestionsPayload
): SpellCheckState {
  const dismissedSuggestions = getSavedDismissedSuggestions();

  const existingSuggestionsToPersist = state.suggestions.filter(
    (suggestion) => {
      // The suggestion was already dismissed
      if (dismissedSuggestions.includes(getUniqueSuggestionId(suggestion))) {
        return false;
      }

      // The text element is included in the new suggestions
      if (
        updatedTextElements.some(
          (updatedTextElement) =>
            updatedTextElement.elementId === suggestion.elementId &&
            updatedTextElement.pageId === suggestion.pageId
        )
      ) {
        return false;
      }

      // The text element is still in the Story
      if (
        !allTextElements.some(
          (storyTextElement) =>
            storyTextElement.id === suggestion.elementId &&
            storyTextElement.pageId === suggestion.pageId
        )
      ) {
        return false;
      }

      return true;
    }
  );

  return {
    ...state,
    isLoadingSuggestions: false,
    suggestions: [
      ...existingSuggestionsToPersist,
      ...newSuggestions.filter(
        (suggestion) =>
          !dismissedSuggestions.includes(getUniqueSuggestionId(suggestion))
      ),
    ],
  };
}

export default updateSuggestions;
