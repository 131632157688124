/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import styled from 'styled-components';
import { Icons } from '@web-stories-wp/design-system';
import { memo, useCallback, useRef } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { useCanvas, useStory } from '../../../../app';
import { useGroupSelection } from './useGroupSelection';
import { LayerText } from '../../../../elements/shared/layerText';
import LayerForm from './layerForm';
import LayerIdContext from './layerIdContext';
import {
  ActionsContainer,
  FadeOutWrapper,
  HiddenIconWrapper,
  LayerButton,
  LayerContainer,
  LayerContentContainer,
  LayerDescription,
  LayerIconWrapper,
  LayerInputWrapper,
  IconWrapper,
} from './layerComponents';
import GroupLayerActions from './groupLayerActions';

const GroupIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 48px;

  svg {
    position: relative;
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.colors.fg.secondary};
  }

  /*
  * moves the click target + positioning to the left
  * this ensures a better click target for
  * the arrow expand collapse state
  */
  transform: translateX(-12px);
  margin-right: -12px;
`;

const ChevronDown = styled(Icons.ChevronDown)`
  min-width: 32px;
  min-height: 32px;
`;

const ChevronRight = styled(Icons.ChevronDown)`
  /*
  * Using ChevronDown and rotating it here
  * vs ChevronRightSmall to keep consistent sizing between states
  */
  min-width: 32px;
  min-height: 32px;
  transform: rotate(-90deg);
`;

function GroupLayer({ groupId }: { groupId: string }) {
  const { groups, updateGroupById, groupLayers } = useStory(
    ({ actions, state }) => ({
      groups: state.currentPage.groups,
      updateGroupById: actions.updateGroupById,
      groupLayers: state.currentPage.elements.filter(
        (el) => el.groupId === groupId
      ),
    })
  );

  const renamableLayer = useCanvas(({ state }) => state.renamableLayer);

  const layerRef = useRef(null);

  // Destructuring with default values in case groups[groupId] is undefined
  const {
    name = '',
    isLocked = false,
    isCollapsed = false,
  } = groups?.[groupId] || {};

  const { isSelected, handleClick } = useGroupSelection(groupId);

  const handleGroupArrowClick = useCallback(() => {
    updateGroupById({
      groupId,
      properties: { isCollapsed: !isCollapsed },
    });
  }, [isCollapsed, groupId, updateGroupById]);

  const LayerIcon = useCallback(
    () => (
      <GroupIconsWrapper>
        {isCollapsed ? (
          <ChevronRight onClick={handleGroupArrowClick} />
        ) : (
          <ChevronDown onClick={handleGroupArrowClick} />
        )}
        <Icons.Group />
      </GroupIconsWrapper>
    ),
    [handleGroupArrowClick, isCollapsed]
  );

  const allLayersHidden = groupLayers.every((layer) => layer.isHidden);
  const LayerVisibilityIcon = () => allLayersHidden && <Icons.VisibilityOff />;

  const layerId = `layer-${groupId}`;
  const isRenameable = renamableLayer?.elementId === groupId;

  const isNested = false;
  const hasLayerHiddenIcon = allLayersHidden;
  const hasLayerLockIcon = isLocked;

  return (
    <LayerContainer>
      {isRenameable ? (
        <LayerInputWrapper isNested={isNested}>
          <LayerIcon />
          <LayerForm
            handleNewLayerName={(newLayerName) =>
              updateGroupById({
                groupId,
                properties: {
                  name: newLayerName,
                },
              })
            }
            layerName={name}
          />
        </LayerInputWrapper>
      ) : (
        <LayerIdContext.Provider value={layerId}>
          <LayerButton
            ref={layerRef}
            id={layerId}
            onClick={handleClick}
            isSelected={isSelected}
            isNested={isNested}
          >
            <LayerIconWrapper isHidden={hasLayerHiddenIcon}>
              <LayerIcon />
            </LayerIconWrapper>
            <LayerDescription>
              <LayerContentContainer>
                <LayerText isHidden={hasLayerHiddenIcon}>{name}</LayerText>
              </LayerContentContainer>
              {(hasLayerHiddenIcon || hasLayerLockIcon) && (
                <FadeOutWrapper>
                  {hasLayerHiddenIcon && (
                    <HiddenIconWrapper>
                      {hasLayerHiddenIcon && <LayerVisibilityIcon />}
                    </HiddenIconWrapper>
                  )}
                  {(hasLayerHiddenIcon || hasLayerLockIcon) && (
                    <IconWrapper>
                      {hasLayerLockIcon && <Icons.LockClosed />}
                    </IconWrapper>
                  )}
                </FadeOutWrapper>
              )}
            </LayerDescription>
          </LayerButton>
          <ActionsContainer>
            <GroupLayerActions groupId={groupId} />
          </ActionsContainer>
        </LayerIdContext.Provider>
      )}
    </LayerContainer>
  );
}

export default memo(GroupLayer);
