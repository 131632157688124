import { saveDismissedSuggestions } from '../../helpers/dismissedSuggestions';
import type { SpellCheckState } from '../../types';
import type { DismissSuggestionPayload } from './types';

function dismissSuggestion(
  state: SpellCheckState,
  { suggestion: suggestionToDismiss }: DismissSuggestionPayload
): SpellCheckState {
  saveDismissedSuggestions([suggestionToDismiss]);

  return {
    ...state,
    suggestions: state.suggestions.filter(
      (suggestion) => suggestion.id !== suggestionToDismiss.id
    ),
  };
}

export default dismissSuggestion;
