import type { SpellCheckState } from '../../types';
import type { DeleteSuggestionsByElementIdsPayload } from './types';

function deleteSuggestionsByElementsIds(
  state: SpellCheckState,
  { elementIds: deletedElementsIds }: DeleteSuggestionsByElementIdsPayload
): SpellCheckState {
  return {
    ...state,
    suggestions: state.suggestions.filter(
      (suggestion) => !deletedElementsIds.includes(suggestion.elementId)
    ),
  };
}

export default deleteSuggestionsByElementsIds;
