/**
 * External dependencies
 */

import styled from 'styled-components';
import { NumericInput } from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */

import { Row } from '../../../form';

export const PillRow = styled(Row)`
  align-items: flex-start;
  justify-content: space-between;
  button {
    flex: 1;
    z-index: 1;
  }
`;

export const Space = styled.div`
  flex: 0 0 20px;
`;

export const StyledNumericInput = styled(NumericInput)`
  flex-grow: 1;
`;
