import { getUniqueSuggestionId } from '../../helpers/suggestionId';
import type { SpellCheckState } from '../../types';
import type { AcceptSuggestionPayload } from './types';

function acceptSuggestion(
  state: SpellCheckState,
  { suggestion: suggestionToAccept }: AcceptSuggestionPayload
): SpellCheckState {
  const { elementId, end, original, pageId, start, suggested } =
    suggestionToAccept;

  const newText = `${original.substring(
    0,
    start
  )}${suggested}${original.substring(end)}`;

  const previousTextLength = end - start;
  const newTextLength = suggested.length;
  const textLengthDifference = newTextLength - previousTextLength;

  const filteredSuggestions = state.suggestions
    .filter(
      (suggestion) =>
        getUniqueSuggestionId(suggestion) !==
        getUniqueSuggestionId(suggestionToAccept)
    )
    .map((suggestion) => {
      if (suggestion.elementId !== elementId || suggestion.pageId !== pageId) {
        return suggestion;
      }

      const isAfterAcceptedSuggestion = suggestion.start > start;

      return {
        ...suggestion,
        original: newText,
        start: isAfterAcceptedSuggestion
          ? suggestion.start + textLengthDifference
          : suggestion.start,
        end: isAfterAcceptedSuggestion
          ? suggestion.end + textLengthDifference
          : suggestion.end,
      };
    });

  return {
    ...state,
    suggestions: filteredSuggestions,
  };
}

export default acceptSuggestion;
