/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import {
  useCallback,
  useBatchingCallback,
  useState,
} from '@web-stories-wp/react';
import { useSnackbar } from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import { useStory } from '../story';
import useGlobalClipboardHandlers from '../../utils/useGlobalClipboardHandlers';
import {
  addElementsToClipboard,
  formatHtmlContent,
  processPastedElements,
} from '../../utils/copyPaste';
import usePasteTextContent from '../../components/richText/usePasteTextContent';
import useUploadWithPreview from '../../components/canvas/useUploadWithPreview';
import useInsertElement from '../../components/canvas/useInsertElement';
import useAddPastedElements from './useAddPastedElements';
import useFigmaFonts from './useFigmaFonts';
import { useStudioContext } from '../../../../../hooks/studioContext';

function useCanvasGlobalKeys() {
  const addPastedElements = useAddPastedElements();
  const { getFigmaElementFont } = useFigmaFonts();
  const { assetType } = useStudioContext();
  const { showSnackbar } = useSnackbar();

  const {
    currentPage,
    selectedElements,
    deleteSelectedElements,
    selectedElementAnimations,
    selectedElementsGroups,
  } = useStory(
    ({
      state: { currentPage, selectedElements, selectedElementAnimations },
      actions: { deleteSelectedElements },
    }) => {
      const selectedElementsGroupsIds = selectedElements
        .map((el) => el.groupId)
        .filter(Boolean);
      const selectedElementsGroupsEntries = Object.entries(
        currentPage?.groups || {}
      ).filter(([groupId]) => selectedElementsGroupsIds.includes(groupId));
      const selectedElementsGroups = Object.fromEntries(
        selectedElementsGroupsEntries
      );

      return {
        currentPage,
        selectedElements,
        deleteSelectedElements,
        selectedElementAnimations,
        selectedElementsGroups,
      };
    }
  );

  const { uploadWithPreview } = useUploadWithPreview();
  const insertElement = useInsertElement();
  const pasteTextContent = usePasteTextContent(insertElement);

  const copyCutHandler = useCallback(
    (evt) => {
      const { type: eventType } = evt;
      if (selectedElements.length === 0) {
        return;
      }

      addElementsToClipboard(
        currentPage,
        selectedElements,
        selectedElementAnimations,
        selectedElementsGroups,
        evt
      );

      if (eventType === 'cut') {
        deleteSelectedElements();
      }
      evt.preventDefault();
    },
    [
      currentPage,
      deleteSelectedElements,
      selectedElements,
      selectedElementAnimations,
      selectedElementsGroups,
    ]
  );

  const elementPasteHandler = useBatchingCallback(
    async (content, isCopiedFromFigma) => {
      const { elements, animations, groups } = processPastedElements(
        content,
        currentPage,
        isCopiedFromFigma
      );

      return await addPastedElements(
        elements,
        animations,
        groups,
        isCopiedFromFigma
      );
    },
    [addPastedElements, currentPage]
  );

  const parseContentFromFigma = useCallback(
    async (textContent) => {
      const parsedContent = JSON.parse(textContent);

      if ('fromStudio' in parsedContent && parsedContent.fromStudio) {
        let transformedPayload = parsedContent.payload;

        const assetTypes = parsedContent.assetTypes;
        const isSupportedAssetTypes = assetTypes.includes(assetType);

        if (!isSupportedAssetTypes) {
          const errorMessage = `The current asset type is a ${assetType}, but the imported asset is ${
            assetTypes.length === 1
              ? assetTypes[0]
              : `one of the following: ${assetTypes.join(', ')}`
          }`;
          const unsupportedError = new Error(errorMessage);
          unsupportedError.name = 'UnsupportedError';

          showSnackbar({
            message: `Unsupported format: ${errorMessage}`,
            dismissable: true,
          });

          return Promise.reject(unsupportedError);
        }

        const itemPromises = transformedPayload.items.map(
          (element, elementIndex) =>
            new Promise(async (resolve, reject) => {
              if (element.type === 'text') {
                const { font, fontWeight } = await getFigmaElementFont(
                  element,
                  () => {
                    const cancelledError = new Error(
                      'User cancelled Figma import'
                    );
                    cancelledError.name = 'CancelledError';

                    reject(cancelledError);
                  }
                );

                // Replace content spans with the new font weight
                const updatedContent = element.content.replace(
                  /font-weight:\s*\d+/g,
                  `font-weight: ${fontWeight}`
                );

                return resolve({
                  ...element,
                  content: updatedContent,
                  font,
                  fontWeight,
                  elementIndex,
                });
              }

              return resolve({ ...element, elementIndex });
            })
        );

        return Promise.allSettled(itemPromises).then((settledResults) => {
          let error = null;

          for (const result of settledResults) {
            if (result.status === 'rejected') {
              error = result.reason;
              break;
            }

            const { elementIndex, ...element } = result.value;
            transformedPayload.items[elementIndex] = element;
          }

          const formattedHtmlContent = formatHtmlContent(
            transformedPayload,
            parsedContent.htmlContent
          );

          if (error) {
            return Promise.reject(error);
          }

          return Promise.resolve(formattedHtmlContent);
        });
      } else {
        throw new Error('Not copied from Figma');
      }
    },
    [assetType]
  );

  const pasteHandler = useCallback(
    async (evt) => {
      const { clipboardData } = evt;

      let textContent = clipboardData.getData('text/plain');
      let htmlContent = clipboardData.getData('text/html');
      const files = clipboardData.files || [];
      const hasFiles = files.length > 0;
      let isCopiedFromFigma = false;

      if (textContent && !htmlContent && !hasFiles) {
        try {
          const htmlContentFromStudio = await parseContentFromFigma(
            textContent
          );

          textContent = '';
          htmlContent = htmlContentFromStudio;
          isCopiedFromFigma = true;
        } catch (figmaImportError) {
          // if user cancelled, do not proceed with paste
          if (
            'name' in figmaImportError &&
            ['CancelledError', 'UnsupportedError'].includes(
              figmaImportError.name
            )
          ) {
            console.log(figmaImportError.message);
            evt.preventDefault();
            return;
          }

          // If not, the content is not from Figma, so we proceed with the regular paste logic
        }
      }

      try {
        // Get the html text and plain text but only if it's not a file being copied.
        const content = !hasFiles && (htmlContent || textContent);

        if (content) {
          const template = document.createElement('template');
          // Remove meta tag.
          template.innerHTML = content
            .replace(/<meta[^>]+>/g, '')
            .replace(/<\/?html>/g, '')
            .replace(/<\/?body>/g, '');
          // First check if it's a paste of "real" elements copied from this editor
          let hasAddedElements = await elementPasteHandler(
            template.content,
            isCopiedFromFigma
          );
          if (!hasAddedElements) {
            // If not, parse as HTML and insert text with formatting
            hasAddedElements = pasteTextContent(template.innerHTML);
          }
          if (hasAddedElements) {
            evt.preventDefault();
          }
        }

        const { items } = clipboardData;

        /**
         * Loop through all items in clipboard to check if correct type. Ignore text here.
         */
        const files = [];
        for (let i = 0; i < items.length; i++) {
          const file = items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
        if (files.length > 0) {
          uploadWithPreview(files);
        }
      } catch (e) {
        // Ignore.
      }
    },
    [elementPasteHandler, pasteTextContent, uploadWithPreview]
  );

  useGlobalClipboardHandlers(copyCutHandler, pasteHandler);

  // @todo: return copy/cut/pasteAction that can be used in the context menus.
}

export default useCanvasGlobalKeys;
