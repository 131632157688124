import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import VideoProps from '../propsTypes/video';
import StyledMediaRowContainer from '../htmlElements/StyledVideoRowContainer';
import MediaRowElement from '../htmlElements/MediaRowElement';
import MediaContainer from '../htmlElements/MediaContainer';
import {
  AssetMetadata,
  AssetMetadataAuthor,
  AssetMetadataSubtitle,
  AssetMetadataTitle,
} from '../htmlElements/AssetMetadataElements';
import ExternalMediaThumbnail from '../components/externalMediaThumbnail';
import { ContentType } from '../../../../../../app/media';
import styled from 'styled-components';
import MediaEditMenu from '../../common/mediaEditMenu';
import DeleteDialog from '../../local/deleteDialog';
import InnerElement from '../../common/innerElement';
import { mapExternalMediaTo3p } from '../../common/mediaDataMapping';

const AssetMetadataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function GeneralImageRowContainer({
  asset,
  isEditMenuExpanded,
  onRowClicked,
  lastElementRef,
  handleOnDelete,
  handleOnEditInAzzuu,
  setExpandedMenuId,
}) {
  const [active, setActive] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const assetRatio = `${asset.ratio * 100}%`;

  const { creator, description, filetype } = asset.metadata;

  const type = filetype === 'image/gif' ? ContentType.GIF : ContentType.IMAGE;

  const elementRef = useRef(null);
  const thumbnailRef = useRef(null);

  const makeActive = useCallback(() => setActive(true), []);
  const makeInactive = useCallback(() => setActive(false), []);

  const handleCloseEditMenu = useCallback(() => {
    setExpandedMenuId(null);
  }, [setExpandedMenuId]);

  const handleOpenEditMenu = useCallback(() => {
    setExpandedMenuId(asset.id);
  }, [asset.id, setExpandedMenuId]);

  const handleDeleteMedia = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, [setIsDeleteDialogOpen]);

  const resource = mapExternalMediaTo3p(asset);

  return (
    <StyledMediaRowContainer ref={lastElementRef}>
      <MediaRowElement onClick={onRowClicked}>
        <MediaContainer
          ref={elementRef}
          onPointerEnter={makeActive}
          onFocus={makeActive}
          onPointerLeave={makeInactive}
          onBlur={makeInactive}
        >
          <ExternalMediaThumbnail assetRatio={assetRatio}>
            <InnerElement
              active={active}
              alt={description}
              isMuted
              mediaElement={thumbnailRef}
              onClick={() => {}}
              onDimensionsUpdate={(dimensions) => {}}
              resource={resource}
              showVideoDetail={false}
              src={asset.playcardSmallUrl ?? asset.url}
              type={type}
            />
          </ExternalMediaThumbnail>
        </MediaContainer>
        <AssetMetadata>
          <AssetMetadataWrapper>
            <AssetMetadataTitle>{asset.title}</AssetMetadataTitle>
            <AssetMetadataAuthor>{creator}</AssetMetadataAuthor>
            {description && description !== asset.title && (
              <AssetMetadataSubtitle>{description}</AssetMetadataSubtitle>
            )}
          </AssetMetadataWrapper>
          {(handleOnDelete || handleOnEditInAzzuu) && (
            <MediaEditMenu
              isOpen={isEditMenuExpanded}
              isTransparent
              handleClose={handleCloseEditMenu}
              handleOpen={handleOpenEditMenu}
              handleOnDelete={handleOnDelete ? handleDeleteMedia : undefined}
              handleOnEditInAzzuu={handleOnEditInAzzuu}
            />
          )}
          {handleOnDelete && (
            <DeleteDialog
              isOpen={isDeleteDialogOpen}
              mediaId={asset.id}
              type={type}
              onClose={() => setIsDeleteDialogOpen(false)}
              handleOnDelete={handleOnDelete}
            />
          )}
        </AssetMetadata>
      </MediaRowElement>
    </StyledMediaRowContainer>
  );
}

GeneralImageRowContainer.propTypes = {
  onRowClicked: PropTypes.func,
  video: VideoProps,
};

export default GeneralImageRowContainer;
