/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Video = styled.video`
  display: block;
  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.borders.radius.small};
  object-fit: cover;
`;

function VideoImage({ alt, ...attrs }) {
  return (
    <Video
      controls={false}
      crossOrigin="anonymous"
      disablePictureInPicture
      muted
      noControls
      title={alt}
      {...attrs}
    />
  );
}

VideoImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default VideoImage;
