// Same as API
export enum PageType {
  Image = 0,
  Video = 1,
  Poll = 2,
  TriviaQuiz = 3,
}

export const engagementUnitNameMap = {
  2: 'Poll:',
  3: 'Trivia Quiz:',
};
