/**
 * Internal dependencies
 */
import * as types from './types';
import * as reducers from './reducers';

function reducer(state, { type, payload }) {
  switch (type) {
    case types.ACCEPT_SUGGESTION: {
      return reducers.acceptSuggestion(state, payload);
    }

    case types.DELETE_SUGGESTIONS_BY_ELEMENTS_IDS: {
      return reducers.deleteSuggestionsByElementsIds(state, payload);
    }

    case types.DISMISS_SUGGESTION: {
      return reducers.dismissSuggestion(state, payload);
    }

    case types.UPDATE_LOADING_STATE: {
      return reducers.updateSuggestionsLoadingState(state, payload);
    }

    case types.UPDATE_SUGGESTIONS: {
      return reducers.updateSuggestions(state, payload);
    }

    default:
      return state;
  }
}

export default reducer;
