import React, { useState } from 'react';
import styled from 'styled-components';
import { CopyPageToClipboardButton } from './copyPageToClipboardButton';
import { ButtonStyle } from './studioButton';
import { useUser, useAnyResourceInLoadingState } from '@hooks';
import { BackToStoryButton } from './backToStoryButton';
import SavePagesButton from './savePagesButton';
import { CopyPageAmpHtmlToClipboardButton } from '@components/header/buttons/copyPageAmpHtmlToClipboardButton';
import { useLocalMedia } from '@src/web-stories-wp/story-editor/src/app';
import { theme } from '@web-stories-wp/design-system';

const ButtonList = styled.nav`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  height: 100%;
`;

const List = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const SaveButton = styled(SavePagesButton)`
  ${ButtonStyle}
  background-color: ${theme.colors.primary};
  &:hover,
  &:active,
  &:focus {
    background-color: ${theme.colors.primaryHover};
    /*important is required cause web-stories styles use it so to override we need to use it also*/
    color: white !important;
  }
`;

function Buttons() {
  const user = useUser();
  const [isSaving, setIsSaving] = useState(false);

  const { isUploading } = useLocalMedia((state) => ({
    isUploading: state.state.isUploading,
  }));

  const anyResourceInLoadingState = useAnyResourceInLoadingState();

  const saveDisabled = isSaving || isUploading || anyResourceInLoadingState;

  return (
    <ButtonList>
      <List>
        {user.isSystemAdmin && (
          <CopyPageAmpHtmlToClipboardButton disabled={saveDisabled} />
        )}
        {user.isSystemAdmin && (
          <CopyPageToClipboardButton disabled={saveDisabled} />
        )}
        <BackToStoryButton disabled={isSaving} />
        <SaveButton
          isLoading={isSaving}
          disabled={saveDisabled}
          onSave={() => setIsSaving(true)}
          onSaveFinish={() => setIsSaving(false)}
        />
      </List>
    </ButtonList>
  );
}

export default Buttons;
