/**
 * External dependencies
 */

import { __ } from '@web-stories-wp/i18n';
import styled from 'styled-components';
import { Button, Text } from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import { Panel } from '../../panel';

export const StyledPanel = styled(Panel)`
  border-bottom-width: 0;
  min-height: 100%;
`;

export const SuggestionText = styled(Text)`
  flex: 1;
`;

export const StyledButton = styled(Button)`
  flex: 1;
  padding: 8px 20px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

export const GoToElementButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray[30]};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const SuggestionContainer = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.quarternary};
`;

export const EmptySuggestionsContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  svg {
    width: 100px;
    height: 100px;
  }

  p {
    text-align: center;
  }
`;

export const EmptySuggestionsText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray[30]};
`;
