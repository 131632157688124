/**
 * Internal dependencies
 */
import * as types from './types';
import type {
  AcceptSuggestionPayload,
  DeleteSuggestionsByElementIdsPayload,
  DismissSuggestionPayload,
  UpdateSuggestionsPayload,
} from './reducers/types';

const acceptSuggestion = (dispatch) => (payload: AcceptSuggestionPayload) =>
  dispatch({
    type: types.ACCEPT_SUGGESTION,
    payload,
  });

const dismissSuggestion = (dispatch) => (payload: DismissSuggestionPayload) =>
  dispatch({
    type: types.DISMISS_SUGGESTION,
    payload,
  });

export const exposedActions = {
  acceptSuggestion,
  dismissSuggestion,
};

const deleteSuggestionsByElementsIds =
  (dispatch) => (payload: DeleteSuggestionsByElementIdsPayload) =>
    dispatch({
      type: types.DELETE_SUGGESTIONS_BY_ELEMENTS_IDS,
      payload,
    });

const updateSuggestions = (dispatch) => (payload: UpdateSuggestionsPayload) =>
  dispatch({
    type: types.UPDATE_SUGGESTIONS,
    payload,
  });

const setIsLoadingSuggestions = (dispatch) => (payload: boolean) =>
  dispatch({
    type: types.UPDATE_LOADING_STATE,
    payload,
  });

export const internalActions = {
  deleteSuggestionsByElementsIds,
  updateSuggestions,
  setIsLoadingSuggestions,
};
