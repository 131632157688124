/**
 * External dependencies
 */
import * as React from '@web-stories-wp/react';
import { useCallback, useDebouncedCallback } from '@web-stories-wp/react';
import { __ } from '@web-stories-wp/i18n';
import { PillGroup } from '@web-stories-wp/design-system';

/**
 * Internal dependencies
 */
import {
  MULTIPLE_DISPLAY_VALUE,
  MULTIPLE_VALUE,
  TEXT_SHADOW_STYLE,
} from '../../../../constants';
import { Color, Row } from '../../../form';
import {
  getCommonValue,
  focusStyle,
  inputContainerStyleOverride,
} from '../../shared';
import { PillRow, Space, StyledNumericInput } from './elements';
import { MIN_MAX, SHADOW_OPTIONS } from './constants';
import type { PanelProps } from '../../types';
import type { TextElement } from '@src/web-stories-wp/story-editor/src/elements/types';

function DropShadowControls({
  selectedElements,
  pushUpdate,
}: PanelProps<TextElement>) {
  const textShadowStyle =
    getCommonValue(selectedElements, 'textShadowStyle') ||
    TEXT_SHADOW_STYLE.NONE;
  const textShadowX = getCommonValue(selectedElements, 'textShadowX');
  const textShadowY = getCommonValue(selectedElements, 'textShadowY');
  const textShadowBlur = getCommonValue(selectedElements, 'textShadowBlur');
  const textShadowColor = getCommonValue(selectedElements, 'textShadowColor');

  const handleUpdateShadowStyle = useCallback(
    (style) =>
      pushUpdate((element) => {
        return {
          textShadowStyle: style,
          textShadowX: element.textShadowX || 0,
          textShadowY: element.textShadowY || 4,
          textShadowBlur: element.textShadowBlur || 4,
          textShadowColor: element.textShadowColor || {
            color: { r: 0, g: 0, b: 0, a: 0.25 },
          },
        };
      }, true),
    [pushUpdate]
  );

  const onFieldBlur = useCallback(
    (fieldName, value) =>
      pushUpdate({ [fieldName]: value }, true /** submit */),
    [pushUpdate]
  );

  const onFieldChange = useDebouncedCallback(onFieldBlur, 1000);

  return (
    <>
      <PillRow>
        <PillGroup
          onSelect={handleUpdateShadowStyle}
          options={SHADOW_OPTIONS}
          pillStyleOverride={focusStyle}
          value={textShadowStyle}
        />
      </PillRow>
      {textShadowStyle !== TEXT_SHADOW_STYLE.NONE && (
        <>
          <Row>
            <StyledNumericInput
              allowEmpty
              aria-label={__('X Coordinates', 'web-stories')}
              canBeNegative
              containerStyleOverride={inputContainerStyleOverride}
              isFloat
              isIndeterminate={MULTIPLE_VALUE === textShadowX}
              min={MIN_MAX.COORDINATES.MIN}
              max={MIN_MAX.COORDINATES.MAX}
              onBlur={(_evt, value) => onFieldBlur('textShadowX', value)}
              onChange={(_evt, value) => onFieldChange('textShadowX', value)}
              placeholder={
                MULTIPLE_VALUE === textShadowX ? MULTIPLE_DISPLAY_VALUE : null
              }
              suffix={__('X', 'Position on X axis', 'web-stories')}
              value={textShadowX}
            />
            <Space />
            <StyledNumericInput
              allowEmpty
              aria-label={__('Y Coordinates', 'web-stories')}
              canBeNegative
              containerStyleOverride={inputContainerStyleOverride}
              isFloat
              isIndeterminate={MULTIPLE_VALUE === textShadowY}
              min={MIN_MAX.COORDINATES.MIN}
              max={MIN_MAX.COORDINATES.MAX}
              onBlur={(_evt, value) => onFieldBlur('textShadowY', value)}
              onChange={(_evt, value) => onFieldChange('textShadowY', value)}
              placeholder={
                MULTIPLE_VALUE === textShadowY ? MULTIPLE_DISPLAY_VALUE : null
              }
              suffix={__('Y', 'Position on Y axis', 'web-stories')}
              value={textShadowY}
            />
          </Row>
          <Row>
            <StyledNumericInput
              allowEmpty
              aria-label={__('Blur', 'web-stories')}
              containerStyleOverride={inputContainerStyleOverride}
              isFloat
              isIndeterminate={MULTIPLE_VALUE === textShadowBlur}
              min={MIN_MAX.BLUR.MIN}
              max={MIN_MAX.BLUR.MAX}
              onBlur={(_evt, value) => onFieldBlur('textShadowBlur', value)}
              onChange={(_evt, value) => onFieldChange('textShadowBlur', value)}
              placeholder={
                MULTIPLE_VALUE === textShadowBlur
                  ? MULTIPLE_DISPLAY_VALUE
                  : null
              }
              suffix={__('Blur', 'Blur', 'web-stories')}
              value={textShadowBlur}
            />
          </Row>
          <Row>
            <Color
              label={__('Drop shadow color', 'web-stories')}
              labelId="drop-shadow-color-label"
              onChange={(value) =>
                pushUpdate(
                  {
                    textShadowColor: value,
                  },
                  true
                )
              }
              value={textShadowColor}
            />
          </Row>
        </>
      )}
    </>
  );
}

export default DropShadowControls;
