/**
 * External dependencies
 */
import * as React from 'react';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from '@web-stories-wp/react';
import { useFeature } from 'flagged';

/**
 * Internal dependencies
 */
import Context from './context';
import useSpellCheckReducer from './useSpellCheckReducer';
import { useStory } from '../app/story';
import { useAPI } from '../app/api';
import elementIs from '../elements/utils/elementIs';
import stripHTML from '../utils/stripHTML';
import type { AcceptSuggestionPayload } from './useSpellCheckReducer/reducers/types';
import type {
  Suggestion,
  TextElementToCheck,
  TextElementWithPageId,
} from './types';

function SpellCheckProvider({ children }: React.PropsWithChildren<{}>) {
  const {
    state: reducerState,
    actions: { api: exposedActions, internal: internalActions },
  } = useSpellCheckReducer();

  const {
    actions: { generateSuggestions },
  } = useAPI();

  const useCopyCheckerInStudio = useFeature('useCopyCheckerInStudio');

  const { updateElementsById, storyTextElements } = useStory(
    ({ actions: { updateElementsById }, state: { pages } }) => {
      const storyTextElements: TextElementWithPageId[] = [];

      for (const page of pages) {
        const pageTextElements = page.elements
          .filter((element) => elementIs.text(element))
          .map((element) => ({
            ...element,
            pageId: page.id,
          })) as TextElementWithPageId[];

        storyTextElements.push(...pageTextElements);
      }

      return {
        storyTextElements,
        updateElementsById,
      };
    }
  );
  const previousTextElements = useRef<TextElementWithPageId[]>([]);

  const fetchSuggestions = useCallback(
    (
      textElements: TextElementToCheck[],
      allTextElements: TextElementWithPageId[]
    ) => {
      internalActions.setIsLoadingSuggestions(true);
      generateSuggestions(textElements)
        .then(
          ({ suggestions: newSuggestions }: { suggestions: Suggestion[] }) => {
            internalActions.updateSuggestions({
              allTextElements,
              suggestions: newSuggestions,
              updatedTextElements: textElements,
            });
          }
        )
        .catch(console.error)
        .finally(() => {
          internalActions.setIsLoadingSuggestions(false);
        });
    },
    []
  );

  useEffect(() => {
    if (!useCopyCheckerInStudio || reducerState.isLoadingSuggestions) {
      return;
    }

    const deletedTextElements: TextElementWithPageId[] =
      previousTextElements.current.filter(
        (previousTextElement) =>
          !storyTextElements.some(
            (textElement) => textElement.id === previousTextElement.id
          )
      );
    const newTextElements: TextElementToCheck[] = [];
    const updatedTextElements: TextElementToCheck[] = [];

    for (const storyTextElement of storyTextElements) {
      const elementContent: string = stripHTML(storyTextElement.content);
      // if the element only has numbers or special characters, skip it
      const containsLetters = /[a-zA-Z]/.test(elementContent);

      if (!containsLetters) {
        continue;
      }

      const previousTextElement = previousTextElements.current.find(
        (previousTextElement) =>
          previousTextElement.id === storyTextElement.id &&
          previousTextElement.pageId === storyTextElement.pageId
      );

      const elementToCheck: TextElementToCheck = {
        elementId: storyTextElement.id,
        original: elementContent,
        pageId: storyTextElement.pageId,
      };

      if (!previousTextElement) {
        newTextElements.push(elementToCheck);
        continue;
      }

      if (storyTextElement.lastUpdatedBy === 'suggestion') {
        continue;
      }

      const isDifferent =
        stripHTML(previousTextElement.content) !== elementContent;

      if (isDifferent) {
        updatedTextElements.push(elementToCheck);
      }
    }

    const someElementsWereDeleted = deletedTextElements.length > 0;
    const someElementsWereCreated = newTextElements.length > 0;
    const someElementsWereUpdated = updatedTextElements.length > 0;

    if (someElementsWereDeleted) {
      internalActions.deleteSuggestionsByElementsIds({
        elementIds: deletedTextElements.map((element) => element.id),
      });
    }

    if (someElementsWereCreated || someElementsWereUpdated) {
      fetchSuggestions(
        [...newTextElements, ...updatedTextElements],
        storyTextElements
      );
    }

    previousTextElements.current = storyTextElements;
  }, [
    fetchSuggestions,
    reducerState.isLoadingSuggestions,
    storyTextElements,
    useCopyCheckerInStudio,
  ]);

  const acceptSuggestion = useCallback(
    ({ suggestion }: AcceptSuggestionPayload) => {
      updateElementsById({
        elementIds: [suggestion.elementId],
        pageId: suggestion.pageId,
        properties: (currentProperties) => {
          const existingContent = currentProperties.content;

          let characterIndexExcludingTags = -1;
          let lastNonTagCharacterIndex = -1;
          let isTagCharacter = false;
          let updatedContent = '';
          let suggestionInserted = false;

          for (
            let characterIndex = 0;
            characterIndex < existingContent.length;
            characterIndex++
          ) {
            const character = existingContent[characterIndex];

            if (character === '<') {
              isTagCharacter = true;
            }

            if (!isTagCharacter) {
              characterIndexExcludingTags++;
              lastNonTagCharacterIndex = characterIndex;
            }

            if (
              characterIndexExcludingTags < suggestion.start ||
              characterIndexExcludingTags >= suggestion.end ||
              isTagCharacter
            ) {
              updatedContent += character;
            }

            if (character === '>') {
              isTagCharacter = false;
            }

            if (
              characterIndexExcludingTags === suggestion.start &&
              !suggestionInserted
            ) {
              updatedContent += suggestion.suggested;
              suggestionInserted = true;
            }
          }

          // Handle cases where the suggestion is at the end of the string
          if (
            characterIndexExcludingTags < suggestion.start &&
            !suggestionInserted
          ) {
            updatedContent =
              existingContent.slice(0, lastNonTagCharacterIndex + 1) +
              suggestion.suggested +
              existingContent.slice(lastNonTagCharacterIndex + 1);
          }

          return {
            ...currentProperties,
            content: updatedContent,
            lastUpdatedBy: 'suggestion',
          };
        },
      });

      exposedActions.acceptSuggestion({ suggestion });
    },
    [updateElementsById]
  );

  const state = {
    state: reducerState,
    actions: { ...exposedActions, acceptSuggestion },
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export default SpellCheckProvider;
