/**
 * External dependencies
 */
import * as React from 'react';
import { __ } from '@web-stories-wp/i18n';

/**
 * Internal dependencies
 */
import { SimplePanel } from '../../panel';
import DropShadowControls from './dropShadow';
import type { PanelProps } from '../../types';
import type { TextElement } from '@src/web-stories-wp/story-editor/src/elements/types';

function StylePanel(props: PanelProps<TextElement>) {
  return (
    <SimplePanel
      name="effects"
      title={__('Effects', 'web-stories')}
      isPersistable
    >
      <DropShadowControls {...props} />
    </SimplePanel>
  );
}

export default StylePanel;
