/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import { useMemo } from '@web-stories-wp/react';
import { ThemeProvider } from 'styled-components';
import {
  Dialog as StyledDialog,
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  theme,
  darkMode,
  lightMode,
} from '@web-stories-wp/design-system';
import type { BaseDialogProps } from '@web-stories-wp/design-system/src/components/dialog';

type DialogProps = BaseDialogProps & {
  // When true, dialog colours won't be reverted
  isDark?: boolean;
  // Action taken on secondary button click when specified.
  onSecondary?: () => void;
  // Action taken on primary button click.
  onPrimary?: () => void;
  // When present, primary button will render.
  primaryText?: string;
  // When present, secondary button will render.
  secondaryText?: string;
  // Unique props needed on primary button, spread on button.
  primaryRest?: Record<string, unknown>;
  // Unique props needed on secondary button, spread on button.
  secondaryRest?: Record<string, unknown>;
  // Custom actions object for when the API restrictions of primary and secondary structure are too much.
  actions?: React.ReactNode;
  // Contents of dialog
  children: React.ReactNode;
};

/**
 * Dialog is wrapped in a ThemeProvider so that colors can be inverted.
 */
const Dialog = ({
  onClose,
  onSecondary,
  onPrimary,
  primaryText,
  secondaryText,
  primaryRest,
  secondaryRest,
  actions,
  children,
  isDark = false,
  ...rest
}: DialogProps) => {
  const _PrimaryButton = useMemo(
    () =>
      primaryText && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          size={BUTTON_SIZES.SMALL}
          onClick={(event) => {
            // Prevent the assets from getting added to the canvas when the dialog is opened/closed
            event.stopPropagation();
            onPrimary?.();
          }}
          {...primaryRest}
        >
          {primaryText}
        </Button>
      ),
    [primaryText, primaryRest, onPrimary]
  );

  const _SecondaryButton = useMemo(
    () =>
      secondaryText && (
        <Button
          type={BUTTON_TYPES.TERTIARY}
          size={BUTTON_SIZES.SMALL}
          onClick={(event) => {
            // Prevent the assets from getting added to the canvas when the dialog is opened/closed
            event.stopPropagation();
            return onSecondary ? onSecondary() : onClose();
          }}
          {...secondaryRest}
        >
          {secondaryText}
        </Button>
      ),
    [secondaryText, secondaryRest, onSecondary, onClose]
  );

  return (
    <ThemeProvider theme={{ ...theme, colors: isDark ? darkMode : lightMode }}>
      <StyledDialog
        onClose={onClose}
        {...rest}
        actions={
          actions || (
            <>
              {_SecondaryButton}
              {_PrimaryButton}
            </>
          )
        }
      >
        {children}
      </StyledDialog>
    </ThemeProvider>
  );
};

export default Dialog;
