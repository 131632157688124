/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { v4 as uuidv4 } from 'uuid';
import { revokeBlob } from '@web-stories-wp/media';

/**
 * Internal dependencies
 */
import { QueueItemState } from './types';
import type {
  AddItemAction,
  BasicAction,
  FinishMutingAction,
  FinishTrimmingAction,
  FinishUploadingAction,
  FinishTranscodingAction,
  QueueState,
  ReplacePlaceholderAction,
  UpdateProgressAction,
} from './types';

/**
 * Add an item to the upload queue.
 */
function addItem(state: QueueState, action: AddItemAction): QueueState {
  const {
    file,
    isBuffer,
    resource,
    onUploadStart,
    onUploadProgress,
    onUploadError,
    onUploadSuccess,
    additionalData,
    posterFile,
    muteVideo,
    trimData,
  } = action.payload;

  const id = isBuffer ? resource.id : uuidv4();
  const newItem = {
    id,
    file,
    isBuffer,
    state: QueueItemState.PENDING,
    resource: {
      ...resource,
      id,
    },
    onUploadStart,
    onUploadProgress,
    onUploadError,
    onUploadSuccess,
    additionalData,
    posterFile,
    progress: 0,
    muteVideo,
    trimData,
  };

  return {
    ...state,
    queue: [...state.queue, newItem],
  };
}

/**
 * Starts uploading a file.
 */
function startUploading(state: QueueState, action: BasicAction): QueueState {
  const { id } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            state: QueueItemState.UPLOADING,
          }
        : item
    ),
  };
}

/**
 * Finishes uploading a file.
 */
function finishUploading(
  state: QueueState,
  action: FinishUploadingAction
): QueueState {
  const { id, resource } = action.payload;

  const queueItem = state.queue.find((item) => item.id === id);

  if (!queueItem) {
    return state;
  }

  if (queueItem.resource.src !== resource.src) {
    revokeBlob(queueItem.resource.src);
    revokeBlob(queueItem.resource.poster);
  }

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            resource,
            posterFile: null,
            progress: 100,
            state: QueueItemState.UPLOADED,
          }
        : item
    ),
  };
}

/**
 * Cancels uploading a file.
 */
function cancelUploading(state: QueueState, action: BasicAction): QueueState {
  const { id } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            progress: 100,
            state: QueueItemState.CANCELLED,
          }
        : item
    ),
  };
}

/**
 * Starts transcoding a file.
 */
function startTranscoding(state: QueueState, action: BasicAction): QueueState {
  const { id } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            state: QueueItemState.TRANSCODING,
            resource: {
              ...item.resource,
              isTranscoding: true,
            },
          }
        : item
    ),
  };
}

/**
 * Finishes transcoding a file.
 */
function finishTranscoding(
  state: QueueState,
  action: FinishTranscodingAction
): QueueState {
  const { id, file } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            file,
            state: QueueItemState.TRANSCODED,
            resource: {
              ...item.resource,
              isTranscoding: false,
            },
          }
        : item
    ),
  };
}

/**
 * Updates the progress for a file.
 */
function updateProgress(
  state: QueueState,
  action: UpdateProgressAction
): QueueState {
  const { id, progress } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            progress,
          }
        : item
    ),
  };
}

/**
 * Starts muting a file.
 */
function startMuting(state: QueueState, action: BasicAction): QueueState {
  const { id } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            state: QueueItemState.MUTING,
            resource: {
              ...item.resource,
              isMuting: true,
            },
          }
        : item
    ),
  };
}

/**
 * Finishes muting a file.
 */
function finishMuting(
  state: QueueState,
  action: FinishMutingAction
): QueueState {
  const { id, file } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            file,
            state: QueueItemState.MUTED,
            resource: {
              ...item.resource,
              isMuting: false,
            },
          }
        : item
    ),
  };
}

/**
 * Starts trimming a file.
 */
function startTrimming(state: QueueState, action: BasicAction): QueueState {
  const { id } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            state: QueueItemState.TRIMMING,
            resource: {
              ...item.resource,
              isTrimming: true,
            },
          }
        : item
    ),
  };
}

/**
 * Finishes trimming a file.
 */
function finishTrimming(
  state: QueueState,
  action: FinishTrimmingAction
): QueueState {
  const { id, file } = action.payload;

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            file,
            state: QueueItemState.TRIMMED,
            resource: {
              ...item.resource,
              isTrimming: false,
            },
          }
        : item
    ),
  };
}

/**
 * Replaces an item's placeholder resource.
 * If the item's resource is not a placeholder, does nothing.
 */
function replacePlaceholderResource(
  state: QueueState,
  action: ReplacePlaceholderAction
): QueueState {
  const { id, resource, posterFile } = action.payload;

  const queueItem = state.queue.find((item) => item.id === id);

  if (!queueItem || !queueItem.resource.isPlaceholder) {
    return state;
  }

  if (queueItem.resource.src !== resource.src) {
    revokeBlob(queueItem.resource.src);
    revokeBlob(queueItem.resource.poster);
  }

  return {
    ...state,
    queue: state.queue.map((item) =>
      item.id === id
        ? {
            ...item,
            resource: {
              ...resource,
              id,
              isPlaceholder: false,
            },
            posterFile,
          }
        : item
    ),
  };
}

/**
 * Removes an item from the queue.
 */
function removeItem(state: QueueState, action: BasicAction): QueueState {
  const { id } = action.payload;

  const newQueue = state.queue.filter((item) => item.id !== id);
  return {
    ...state,
    queue: newQueue,
  };
}

export const reducer = {
  addItem,
  removeItem,
  // Uploading state
  cancelUploading,
  finishUploading,
  startUploading,
  updateProgress,
  // Transcoding state
  startTranscoding,
  finishTranscoding,
  // Muting state
  startMuting,
  finishMuting,
  // Trimming state
  startTrimming,
  finishTrimming,
  // Placeholder
  replacePlaceholderResource,
};
