/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  BUTTON_TYPES,
  TOOLTIP_PLACEMENT,
  theme,
  themeHelpers,
} from '@web-stories-wp/design-system';
import { useRef, memo, useContext } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import Tooltip from '../../../tooltip';
import LayerIdContext from './layerIdContext';

const ProgressWrapper = styled('div')`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButton = styled(Button).attrs({
  type: BUTTON_TYPES.PLAIN,
  tabIndex: -1,
})`
  position: relative;
  aspect-ratio: 1;
  width: 20px;
  padding: 0;
  color: ${theme.colors.fg.secondary};

  /*
   * all of our Icons right now have an embedded padding,
   * however the new designs just disregard this embedded
   * padding, so to accommodate, we'll make the icon its
   * intended size and manually center it within the button.
   */
  svg {
    position: absolute;
    width: 32px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${ProgressWrapper} svg {
    width: 20px;
  }

  /*
   * override base button background color so we can receive the
   * proper background color from the parent.
   */
  && {
    transition: revert;
    background: var(--background-color);
  }

  :disabled {
    color: ${({ theme }) => theme.colors.fg.secondary};
  }

  :hover {
    color: var(
      --selected-hover-color,
      ${({ theme }) => theme.colors.fg.primary}
    );
  }

  & + & {
    margin-left: 4px;
  }

  * {
    pointer-events: none;
  }

  :focus {
    ${({ theme }) =>
      themeHelpers.focusCSS(
        theme.colors.border.focus,
        'var(--background-color)'
      )}
  }
`;

function preventReorder(e) {
  e.stopPropagation();
  e.preventDefault();
}

type LayerActionProps = {
  children: React.ReactNode;
  disabled?: boolean;
  handleClick: () => void;
  isActive?: boolean;
  isLoading?: boolean;
  label: string;
};

function LayerAction({
  children,
  disabled = false,
  handleClick,
  label,
  isActive = true,
  isLoading = false,
}: LayerActionProps) {
  const buttonRef = useRef(null);

  const layerId = useContext(LayerIdContext);

  if (!isActive) {
    return null;
  }

  return (
    <Tooltip title={label} hasTail isDelayed placement={TOOLTIP_PLACEMENT.TOP}>
      <ActionButton
        aria-label={label}
        aria-describedby={layerId}
        onPointerDown={preventReorder}
        disabled={disabled}
        onClick={handleClick}
        ref={buttonRef}
      >
        {isLoading ? (
          <ProgressWrapper>
            <CircularProgress size={20} thickness={2} />
          </ProgressWrapper>
        ) : (
          children
        )}
      </ActionButton>
    </Tooltip>
  );
}

export default memo(LayerAction);
