/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useState } from '@web-stories-wp/react';
import { Icons } from '@web-stories-wp/design-system';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import StoryPropTypes from '../../types';
import VideoImage from '../media/videoImage';
import VisibleImage from '../media/visibleImage';

function VideoLayerContent({
  element: {
    resource: { poster: defaultPoster, alt, src },
    poster,
  },
  showVideoPreviewAsBackup,
}) {
  const [hasError, setHasError] = useState(false);
  const iconImage = poster?.length ? poster : defaultPoster;

  if (hasError || (!iconImage && !showVideoPreviewAsBackup)) {
    return <Icons.Video width={32} height={32} title={alt} />;
  }

  if (!iconImage && showVideoPreviewAsBackup) {
    return <VideoImage src={src} alt={alt} onError={() => setHasError(true)} />;
  }

  return (
    <VisibleImage
      src={iconImage}
      alt={alt}
      width={32}
      height={32}
      onError={() => setHasError(true)}
    />
  );
}

VideoLayerContent.propTypes = {
  element: StoryPropTypes.element.isRequired,
  showVideoPreviewAsBackup: PropTypes.bool,
};

export default VideoLayerContent;
