import {
  dataToEditorX,
  dataToEditorY,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  DEFAULT_DPR,
  DEFAULT_ASSET_PAGE_RATIO,
} from '@web-stories-wp/units';

export function getDefaultInsertedElementSize({
  height,
  width,
  pageHeight,
  pageWidth,
}: {
  height: number;
  width: number;
  pageHeight: number;
  pageWidth: number;
}): { height: number; width: number } {
  const ratio = width / height;

  width = Math.min(width * DEFAULT_DPR, PAGE_WIDTH * DEFAULT_ASSET_PAGE_RATIO);
  height = width / ratio;

  // Ensure that the element fits on the page.
  if (width > PAGE_WIDTH || height > PAGE_HEIGHT) {
    const pageRatio = PAGE_WIDTH / PAGE_HEIGHT;
    const newRatio = width / height;
    if (newRatio <= pageRatio) {
      width = Math.min(width, PAGE_WIDTH);
      height = width / newRatio;
    } else {
      height = Math.min(height, PAGE_HEIGHT);
      width = height * newRatio;
    }
  }

  width = dataToEditorX(width, pageWidth);
  height = dataToEditorY(height, pageHeight);

  return { width, height };
}
