/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import { useCallback } from '@web-stories-wp/react';
import { __ } from '@web-stories-wp/i18n';
import {
  Button,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
  Icons,
  Text,
  Tooltip,
} from '@web-stories-wp/design-system';
import type { Suggestion } from '@src/web-stories-wp/story-editor/src/spellCheck/types';

/**
 * Internal dependencies
 */
import { useStory } from '../../../../app/story';
import { useSpellCheck } from '../../../../spellCheck';
import {
  Actions,
  EmptySuggestionsContainer,
  EmptySuggestionsText,
  GoToElementButton,
  SuggestionContainer,
  TextContainer,
  StyledButton,
  StyledPanel,
  SuggestionText,
} from './components';
import { getUniqueSuggestionId } from '@src/web-stories-wp/story-editor/src/spellCheck/helpers/suggestionId';

function ExcerptPanel() {
  const {
    actions: { acceptSuggestion, dismissSuggestion },
    state: { suggestions },
  } = useSpellCheck();
  const { setCurrentPage, setSelectedElementsById } = useStory(
    ({ actions: { setCurrentPage, setSelectedElementsById } }) => ({
      setCurrentPage,
      setSelectedElementsById,
    })
  );

  const handleSelectElement = useCallback((suggestion: Suggestion) => {
    setCurrentPage({ pageId: suggestion.pageId });
    setSelectedElementsById({ elementIds: [suggestion.elementId] });
  }, []);

  const renderSuggestion = ({
    end,
    original,
    start,
    suggested,
  }: Suggestion) => {
    const stringToReplace = original.substring(start, end);

    const elements: JSX.Element[] = [
      <span key="before">{original.substring(0, start)}</span>,
      <s key="original">{stringToReplace}</s>,
      <strong key="suggested"> {suggested} </strong>,
      <span key="after">{original.substring(end)}</span>,
    ];

    return elements;
  };

  return (
    <StyledPanel
      canCollapse={false}
      name="suggestions"
      ariaLabel={__('Alignment', 'web-stories')}
    >
      {suggestions.length === 0 ? (
        <EmptySuggestionsContainer>
          <Icons.SpellCheck />
          <Text isBold>No suggestions found.</Text>
          <EmptySuggestionsText>Your copy looks good!</EmptySuggestionsText>
        </EmptySuggestionsContainer>
      ) : (
        suggestions.map((suggestion) => (
          <SuggestionContainer key={getUniqueSuggestionId(suggestion)}>
            <TextContainer>
              <SuggestionText>{renderSuggestion(suggestion)}</SuggestionText>
              <Tooltip title="Select element" hasTail>
                <GoToElementButton
                  variant={BUTTON_VARIANTS.SQUARE}
                  type={BUTTON_TYPES.TERTIARY}
                  size={BUTTON_SIZES.SMALL}
                  onClick={() => handleSelectElement(suggestion)}
                  aria-label="Select element"
                >
                  <Icons.GoToElement />
                </GoToElementButton>
              </Tooltip>
            </TextContainer>
            <Actions>
              <StyledButton
                variant={BUTTON_VARIANTS.RECTANGLE}
                type={BUTTON_TYPES.SECONDARY}
                size={BUTTON_SIZES.SMALL}
                onClick={() => acceptSuggestion({ suggestion })}
              >
                Apply
              </StyledButton>
              <StyledButton
                variant={BUTTON_VARIANTS.RECTANGLE}
                type={BUTTON_TYPES.QUATERNARY}
                size={BUTTON_SIZES.SMALL}
                onClick={() => dismissSuggestion({ suggestion })}
              >
                Dismiss
              </StyledButton>
            </Actions>
          </SuggestionContainer>
        ))
      )}
    </StyledPanel>
  );
}

export default ExcerptPanel;
