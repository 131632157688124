/**
 * External dependencies
 */
import { identity, useContextSelector } from '@web-stories-wp/react';
/**
 * Internal dependencies
 */
import Context from './context';
import type { SpellCheckContext } from './types';

export function useSpellCheck(): SpellCheckContext;
export function useSpellCheck<T>(selector: (state: SpellCheckContext) => T): T;
export function useSpellCheck<T>(
  selector: (state: SpellCheckContext) => T | SpellCheckContext = identity
) {
  return useContextSelector(Context, selector);
}

export default useSpellCheck;
