/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * External dependencies
 */
import { useCallback } from '@web-stories-wp/react';
/**
 * Internal dependencies
 */
import { useCanvas, useStory } from '../../../../app';

export function useGroupSelection(groupId) {
  const { toggleLayer, groupLayers, selectedElementIds } = useStory(
    ({ state, actions }) => ({
      groupLayers: state.currentPage.elements.filter(
        (el) => el.groupId === groupId
      ),
      selectedElementIds: state.selectedElementIds,
      toggleLayer: actions.toggleLayer,
    })
  );
  const setRenamableLayer = useCanvas(
    ({ actions }) => actions.setRenamableLayer
  );

  const isSelected = groupLayers.every((gl) =>
    selectedElementIds.includes(gl.id)
  );

  const handleClick = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();

      // Utilize the withLinked option and use only first layer id
      toggleLayer({
        elementId: groupLayers[0].id,
        metaKey: evt.metaKey,
        shiftKey: evt.shiftKey,
        withLinked: true,
      });

      const isDoubleClick = evt.detail === 2;
      if (isDoubleClick) {
        setRenamableLayer({ elementId: groupId });
      }
    },
    [toggleLayer, groupLayers, setRenamableLayer, groupId]
  );

  return { isSelected, handleClick };
}
