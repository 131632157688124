/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback, useEffect, useMemo, useRef } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { useHistory } from '../../history';
import deleteNestedKeys from '../utils/deleteNestedKeys';
import pageContainsBlobUrl from '../utils/pageContainsBlobUrl';

// Changes to these properties of elements do not create a new history entry
// if only one (or multiple) of these properties change but nothing else changes.
// These are still saved as part of changes involving other properties.
const PAGE_PROPS_TO_IGNORE = ['cssAnimations'];
const ELEMENT_PROPS_TO_IGNORE = [
  'resource.baseColor',
  'resource.blurHash',
  'resource.id',
  'resource.isMuted',
  'resource.posterId',
  'resource.poster',
  'resource.font.metrics',
  'resource.font.weights',
  'resource.font.variants',
  'resource.font.fallbacks',
  'resource.font.styles',
  'resource.isOptimized',
  'resource.length',
  'resource.lengthFormatted',
  'resource.trimData.original',
  'resource.trimData.start',
  'resource.trimData.end',
  'resource.creationDate',
  'resource.loading',
  'resource.src',
  'resource.sizes',
];

// Record any change to core variables in history (history will know if it's a replay)
function useHistoryEntry({ story, current, pages, selection, capabilities }) {
  const {
    state: { currentEntry },
    actions: { stateToHistory },
  } = useHistory();

  const currentHistoryEntryRef = useRef();

  const deleteKeysFromPages = useCallback((list) => {
    // Create a copy of the list not to influence the original.
    return structuredClone(list).map((page) => {
      PAGE_PROPS_TO_IGNORE.forEach((prop) => delete page[prop]);
      page.elements.forEach(deleteNestedKeys(ELEMENT_PROPS_TO_IGNORE));
      return page;
    });
  }, []);

  const pagesWithoutKeys = useMemo(() => {
    return deleteKeysFromPages(pages);
  }, [pages]);

  const getEntryWithoutPropsToIgnore = useCallback((entry) => {
    if (!entry) {
      return null;
    }

    const entryWithoutPropsToIgnore = structuredClone(entry);
    entryWithoutPropsToIgnore.pages = deleteKeysFromPages(entry.pages);

    return entryWithoutPropsToIgnore;
  }, []);

  useEffect(() => {
    if (!currentEntry) {
      return;
    }

    const entryWithoutPropsToIgnore =
      getEntryWithoutPropsToIgnore(currentEntry);

    const currentEntryWithoutPropsToIgnore = getEntryWithoutPropsToIgnore(
      currentHistoryEntryRef.current
    );

    if (
      !currentEntryWithoutPropsToIgnore ||
      JSON.stringify(entryWithoutPropsToIgnore) !==
        JSON.stringify(currentEntryWithoutPropsToIgnore)
    ) {
      currentHistoryEntryRef.current =
        getEntryWithoutPropsToIgnore(currentEntry);
    }
  }, [currentEntry, getEntryWithoutPropsToIgnore]);

  const currentPageIndexRef = useRef();
  const selectedElementIdsRef = useRef();
  useEffect(() => {
    currentPageIndexRef.current = current;
    selectedElementIdsRef.current = selection;
  }, [current, selection]);

  useEffect(() => {
    // There are some element properties that should not influence history.
    // Before adding a new history entry, let's check if the only properties that changed
    // should not influence history. Then we skip adding an entry.
    let skipAddingEntry = false;
    let shouldMergeWithLastEntry = false;

    if (
      ELEMENT_PROPS_TO_IGNORE.length &&
      currentHistoryEntryRef.current &&
      pages?.length
    ) {
      // If story / capabilities change, we should always add a new entry.
      const withoutPages = {
        story,
        capabilities,
      };
      const onlyPagesChanged = Object.keys(withoutPages).every(
        (key) =>
          JSON.stringify(withoutPages[key]) ===
          JSON.stringify(currentHistoryEntryRef.current[key])
      );
      // If only pages have changed, check if relevant properties have changed.
      if (onlyPagesChanged) {
        const adjustedPages = deleteKeysFromPages(pages);
        const adjustedEntryPages = deleteKeysFromPages(
          currentHistoryEntryRef.current.pages
        );
        const pageElementWasUpdatedBySuggestion = adjustedEntryPages.some(
          (page) =>
            page.elements.some(
              (element) => element.lastUpdatedBy === 'suggestion'
            )
        );

        // When accepting a spell-check suggestion, the pages are updated twice:
        // - 1. When the suggestion is accepted and the text is updated (the lastUpdatedBy value is 'suggestion')
        // - 2. When the text box is resized based on the new text length (the lastUpdatedBy value is 'user')
        // However, we don't want these to be recorded as separate history entries, so we can ignore the first update.
        // (the second update will always happen, even if the text box is the same size, the lastUpdatedBy value will get updated anyway)
        if (pageElementWasUpdatedBySuggestion) {
          shouldMergeWithLastEntry = true;
        }
        // Check if after removing properties that shouldn't influence history, nothing changed.
        // Is so, let's skip adding a history entry.
        skipAddingEntry =
          JSON.stringify(adjustedPages) === JSON.stringify(adjustedEntryPages);
      }

      if (pageContainsBlobUrl(pages)) {
        skipAddingEntry = true;
      }
    }

    if (!skipAddingEntry) {
      stateToHistory(
        {
          story,
          current: currentPageIndexRef.current,
          selection: selectedElementIdsRef.current,
          pages,
          capabilities,
        },
        shouldMergeWithLastEntry
      );
    }
  }, [story, pages, stateToHistory, capabilities]);
}

export default useHistoryEntry;
