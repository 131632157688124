import { getUniqueSuggestionId } from './suggestionId';
import type { DismissedSuggestions, Suggestion } from '../types';

const DISMISSED_SUGGESTIONS_LOCAL_STORAGE_KEY = 'dismissedSuggestions';

export function getSavedDismissedSuggestions() {
  const dismissedSuggestionsString = localStorage.getItem(
    DISMISSED_SUGGESTIONS_LOCAL_STORAGE_KEY
  );

  const dismissedSuggestions: DismissedSuggestions = dismissedSuggestionsString
    ? dismissedSuggestionsString.split(',')
    : [];

  return dismissedSuggestions;
}

export function saveDismissedSuggestions(suggestions: Suggestion[]) {
  const dismissedSuggestions = getSavedDismissedSuggestions();

  localStorage.setItem(
    DISMISSED_SUGGESTIONS_LOCAL_STORAGE_KEY,
    [...dismissedSuggestions, ...suggestions.map(getUniqueSuggestionId)].join(
      ','
    )
  );
}
