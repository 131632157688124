import * as React from 'react';
import styled from 'styled-components';
import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import BaseDialog from '@web-stories-wp/story-editor/src/components/dialog';
import { BUTTON_TYPES } from '@web-stories-wp/design-system';

const DialogText = styled(Text)`
  text-align: center;
`;

const Dialog = styled(BaseDialog)`
  max-width: 320px;
  position: relative;
`;

function IgnoreSuggestionsDialog({
  isLoading,
  isOpen,
  onClose,
  onIgnoreSuggestions,
}: {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onIgnoreSuggestions: () => void;
}) {
  return (
    <Dialog
      isDark
      isOpen={isOpen}
      onClose={onClose}
      ariaHideApp={false}
      primaryText="Save"
      primaryRest={{ isLoading, type: BUTTON_TYPES.PRIMARY }}
      onPrimary={onIgnoreSuggestions}
      secondaryText="Cancel"
      secondaryRest={{ type: BUTTON_TYPES.QUATERNARY }}
      stackedActions
      withCloseButton
    >
      <DialogText size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.MEDIUM}>
        There are unresolved suggestions for this Story.
        <br />
        Do you still want to save?
      </DialogText>
    </Dialog>
  );
}

export default IgnoreSuggestionsDialog;
