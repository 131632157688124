/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import { useCallback, useRef, useState } from '@web-stories-wp/react';
import { __ } from '@web-stories-wp/i18n';
import { Icons } from '@web-stories-wp/design-system';
import {
  getLayerName,
  type Element,
} from '@web-stories-wp/story-editor/src/elements';

/**
 * Internal dependencies
 */
import { getDefinitionForType } from '../../../../elements';
import { useCanvas, useStory } from '../../../../app';
import { LayerText } from '../../../../elements/shared/layerText';
import useCORSProxy from '../../../../utils/useCORSProxy';
import useLayerSelection from './useLayerSelection';
import LayerForm from './layerForm';
import ElementLayerActions from './elementLayerActions';
import LayerIdContext from './layerIdContext';
import {
  ActionsContainer,
  FadeOutWrapper,
  HiddenIconWrapper,
  LayerButton,
  LayerContainer,
  LayerContentContainer,
  LayerDescription,
  LayerIconWrapper,
  LayerInputWrapper,
  IconWrapper,
} from './layerComponents';

function ElementLayer({ layer }: { layer: Element }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const renamableLayer = useCanvas(({ state }) => state.renamableLayer);
  const layerRef = useRef(null);
  const layerName = getLayerName(layer);
  const { hasDownloadMenu, LayerIcon } = getDefinitionForType(layer.type);
  const { isSelected, handleClick } = useLayerSelection(layer);
  const { currentPage, currentPageBackgroundColor, groups, updateElementById } =
    useStory(({ actions, state }) => ({
      currentPage: state.currentPage,
      currentPageBackgroundColor:
        !layer.isDefaultBackground || state.currentPage?.backgroundColor,
      groups: state.currentPage?.groups || {},
      updateElementById: actions.updateElementById,
    }));
  const { getProxiedUrl } = useCORSProxy();
  const isBackground = currentPage.elements[0].id === layer.id;
  const isRenameable = renamableLayer?.elementId === layer.id;

  const isNested = Boolean(layer.groupId);

  const hasLayerLockIcon = Boolean(isBackground || layer.isLocked);
  const hasLayerHiddenIcon = layer.isHidden;
  const hasActions = !isBackground || hasDownloadMenu;

  const LayerLockIcon = useCallback(
    () =>
      isBackground || isNested ? (
        <Icons.LockFilledClosed />
      ) : (
        <Icons.LockClosed />
      ),
    [isBackground, isNested]
  );

  const group = groups[layer.groupId];

  if (group?.isCollapsed) {
    return null;
  }

  return (
    <LayerContainer isDownloading={isDownloading}>
      {isRenameable ? (
        <LayerInputWrapper isNested={isNested}>
          <LayerIconWrapper>
            <LayerIcon
              element={layer}
              getProxiedUrl={getProxiedUrl}
              currentPageBackgroundColor={currentPageBackgroundColor}
            />
          </LayerIconWrapper>
          <LayerForm
            handleNewLayerName={(newLayerName: string) =>
              updateElementById({
                elementId: layer.id,
                properties: { layerName: newLayerName },
              })
            }
            layerName={layerName}
          />
        </LayerInputWrapper>
      ) : (
        <LayerIdContext.Provider value={layer.id}>
          <LayerButton
            ref={layerRef}
            id={`layer-${layer.id}`}
            onClick={handleClick}
            isNested={isNested}
            isSelected={isSelected}
          >
            <LayerIconWrapper isHidden={hasLayerHiddenIcon}>
              <LayerIcon
                element={layer}
                getProxiedUrl={getProxiedUrl}
                currentPageBackgroundColor={currentPageBackgroundColor}
              />
            </LayerIconWrapper>
            <LayerDescription>
              <LayerContentContainer>
                <LayerText isHidden={hasLayerHiddenIcon}>{layerName}</LayerText>
              </LayerContentContainer>
              {(hasLayerHiddenIcon || hasLayerLockIcon) && (
                <FadeOutWrapper>
                  {hasLayerHiddenIcon && (
                    <HiddenIconWrapper isLayerHidden>
                      {hasLayerHiddenIcon && <Icons.VisibilityOff />}
                    </HiddenIconWrapper>
                  )}
                  <IconWrapper isLayerHidden={hasLayerHiddenIcon}>
                    {hasLayerLockIcon ? <LayerLockIcon /> : <Icons.LockOpen />}
                  </IconWrapper>
                </FadeOutWrapper>
              )}
            </LayerDescription>
          </LayerButton>
          {hasActions && (
            <ActionsContainer>
              <ElementLayerActions
                element={layer}
                isBackground={isBackground}
                isDownloading={isDownloading}
                setIsDownloading={setIsDownloading}
              />
            </ActionsContainer>
          )}
        </LayerIdContext.Provider>
      )}
    </LayerContainer>
  );
}

export default ElementLayer;
