import type { SpellCheckState } from '../../types';

function updateSuggestionsLoadingState(
  state: SpellCheckState,
  isLoadingSuggestions: boolean
): SpellCheckState {
  return {
    ...state,
    isLoadingSuggestions,
  };
}

export default updateSuggestionsLoadingState;
