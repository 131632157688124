import styled from 'styled-components';
import ChevronIcon from '../chevron.svg';
import { PANE_PADDING } from '@web-stories-wp/story-editor/src/components/library/panes/shared';

const OptionsListBackButton = styled.div`
  align-items: center;
  background-color: transparent;
  border: none;
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  color: white;
  gap: 8px;
  padding: 4px ${PANE_PADDING} 0;
  min-height: var(--icon-button-size);

  &:before {
    content: '';
    display: block;
    background: url(${ChevronIcon});
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center;
    height: var(--icon-button-size);
    width: var(--icon-button-size);
    transform: rotate(180deg);
  }
`;

export default OptionsListBackButton;
