/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useBatchingCallback } from '@web-stories-wp/react';
import { v4 as uuidv4 } from 'uuid';
/**
 * Internal dependencies
 */
import { useStory } from '../story';
import useUploadWithPreview from '../../components/canvas/useUploadWithPreview';
import { calculateTextHeight } from '../../utils/textMeasurements';

function useAddPastedElements() {
  const { uploadBase64WithPreview } = useUploadWithPreview();
  const {
    currentPage,
    addElements,
    addGroup,
    arrangeElements,
    updateCurrentPageProperties,
    deleteElementById,
    combineElements,
    addAnimations,
  } = useStory(
    ({
      state: { currentPage, selectedElements },
      actions: {
        addElements,
        addGroup,
        arrangeElements,
        deleteSelectedElements,
        updateCurrentPageProperties,
        deleteElementById,
        combineElements,
        addAnimations,
      },
    }) => {
      return {
        currentPage,
        selectedElements,
        addElements,
        addGroup,
        arrangeElements,
        deleteSelectedElements,
        updateCurrentPageProperties,
        deleteElementById,
        combineElements,
        addAnimations,
      };
    }
  );

  const addPastedElements = useBatchingCallback(
    async (
      elements,
      animations = [],
      groups = [],
      isCopiedFromFigma = false
    ) => {
      if (elements.length === 0) {
        return Promise.resolve(false);
      }

      const isUploadable = (element) => {
        return element.type === 'image' && element.resource.base64Str;
      };

      const elementsWithUpdatedPositions = elements.map(
        (nonBackgroundElement) => {
          if (nonBackgroundElement.type !== 'text') {
            return nonBackgroundElement;
          }

          if (!('figmaFontName' in nonBackgroundElement)) {
            return nonBackgroundElement;
          }

          const newTextHeight = calculateTextHeight(
            nonBackgroundElement,
            nonBackgroundElement.width
          );

          const heightDifference = newTextHeight - nonBackgroundElement.height;
          const newYPosition = nonBackgroundElement.y - heightDifference / 2;

          return {
            ...nonBackgroundElement,
            height: newTextHeight,
            y: newYPosition,
          };
        }
      );

      const elementsToUpload = elementsWithUpdatedPositions.filter((element) =>
        isUploadable(element)
      );

      if (elementsToUpload.length) {
        // Upload all images first
        await uploadBase64WithPreview(elementsToUpload);
      }

      const nonUploadableElements = elementsWithUpdatedPositions.filter(
        (element) => !isUploadable(element)
      );

      // If a bg element is pasted, handle that first
      const newBackgroundElement = nonUploadableElements.find(
        ({ isBackground }) => isBackground
      );
      let newAnimations = animations;

      if (newBackgroundElement) {
        const existingBgElement = currentPage.elements[0];
        if (newBackgroundElement.isDefaultBackground) {
          // The user has pasted a non-media background from another page:
          // Delete existing background (if any) and then update page
          // with this default element background color
          if (!existingBgElement.isDefaultBackground) {
            deleteElementById({ elementId: existingBgElement.id });
          }
          updateCurrentPageProperties({
            properties: {
              backgroundColor: newBackgroundElement.backgroundColor,
            },
          });
        } else {
          // Since background will maintain id, we update any
          // new animations to have the proper target
          newAnimations = animations.map((animation) =>
            animation.targets.includes(newBackgroundElement.id)
              ? { ...animation, targets: [existingBgElement.id] }
              : animation
          );
          // The user has pasted a media background from another page:
          // Merge this element into the existing background element on this page
          combineElements({
            firstElement: newBackgroundElement,
            secondId: existingBgElement.id,
            shouldRetainAnimations: false,
          });
        }
      }

      // Then add all regular elements if any exist
      const nonBackgroundElements = nonUploadableElements.filter(
        ({ isBackground }) => !isBackground
      );

      if (!isCopiedFromFigma) {
        const groupsEntries = Object.entries(groups);
        const newGroups = groupsEntries.map(([oldGroupId, group]) => {
          const newGroupId = uuidv4();
          return [[oldGroupId, newGroupId], group];
        });

        const nonBackgroundElementsWithNewGroups = nonBackgroundElements.map(
          (el) => {
            if (!el.groupId) {
              return el;
            }
            const newGroup = newGroups.find(
              ([[oldGroupId]]) => oldGroupId === el.groupId
            );
            if (!newGroup) {
              return el;
            }
            const [[, newGroupId]] = newGroup;
            return {
              ...el,
              groupId: newGroupId,
            };
          }
        );

        if (nonBackgroundElementsWithNewGroups.length) {
          addElements({ elements: nonBackgroundElementsWithNewGroups });
        }

        if (newGroups.length) {
          for (const newGroup of newGroups) {
            const [[, newGroupId], group] = newGroup;
            addGroup({ groupId: newGroupId, name: group.name });
          }
        }

        // Add any animations associated with the new elements
        addAnimations({ animations: newAnimations });

        return Promise.resolve(true);
      }

      if (nonBackgroundElements.length) {
        addElements({ elements: nonBackgroundElements });
      }

      arrangeElements({
        elements: elementsWithUpdatedPositions,
      });

      const groupIds = Object.keys(groups);

      if (groupIds.length) {
        for (const groupId of groupIds) {
          addGroup({ groupId, name: groups[groupId].name });
        }
      }

      return Promise.resolve(true);
    },
    [
      addElements,
      addGroup,
      arrangeElements,
      currentPage,
      updateCurrentPageProperties,
      combineElements,
      deleteElementById,
      addAnimations,
    ]
  );

  return addPastedElements;
}

export default useAddPastedElements;
