/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import PropTypes from 'prop-types';
import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from '@web-stories-wp/react';
import styled, { css } from 'styled-components';
import { generatePatternStyles } from '@web-stories-wp/patterns';
import { useUnits } from '@web-stories-wp/units';
import { StoryAnimation } from '@web-stories-wp/animation';
import { useStory } from '@web-stories-wp/story-editor';

/**
 * Internal dependencies
 */
import { elementIs, getDefinitionForType } from '../../elements';
import {
  elementWithPosition,
  elementWithRotation,
  elementWithSize,
} from '../../elements/shared';
import WithMask from '../../masks/display';
import StoryPropTypes from '../../types';
import { useTransformHandler } from '../transform';
import useColorTransformHandler from '../../elements/shared/useColorTransformHandler';
import {
  getBorderPositionCSS,
  getResponsiveBorder,
  shouldDisplayBorder,
} from '../../utils/elementBorder';

// Using attributes to avoid creation of hundreds of classes by styled components for previewMode.
const Wrapper = styled.div.attrs(
  ({ previewMode, x, y, width, height, rotationAngle }) => {
    const style = {
      position: 'absolute',
      zIndex: 1,
      left: `${x}px`,
      top: `${y}px`,
      width: `${width}px`,
      height: `${height}px`,
      transform: `rotate(${rotationAngle}deg)`,
    };
    return previewMode ? { style } : {};
  }
)`
  ${({ previewMode }) => !previewMode && elementWithPosition}
  ${({ previewMode }) => !previewMode && elementWithSize}
  ${({ previewMode }) => !previewMode && elementWithRotation}
  contain: layout;
  transition: opacity 0.15s cubic-bezier(0, 0, 0.54, 1);

  ${({ isBackground, theme }) =>
    isBackground &&
    css`
      border-radius: ${theme.borders.radius.small};
      overflow: hidden;
    `}
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ReplacementContainer = styled.div`
  transition: opacity 0.25s cubic-bezier(0, 0, 0.54, 1);
  pointer-events: none;
  opacity: ${({ hasReplacement }) => (hasReplacement ? 1 : 0)};
  height: 100%;
`;

function AnimationWrapper({ children, id, isAnimatable }) {
  return isAnimatable ? (
    <StoryAnimation.WAAPIWrapper target={id}>
      {children}
    </StoryAnimation.WAAPIWrapper>
  ) : (
    children
  );
}
AnimationWrapper.propTypes = {
  isAnimatable: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
};

function DisplayElement({ element, previewMode, isAnimatable = false }) {
  const { getBox, dataToEditorX } = useUnits((state) => ({
    getBox: state.actions.getBox,
    dataToEditorX: state.actions.dataToEditorX,
  }));

  const { updateElementsById } = useStory(
    ({ actions: { updateElementsById } }) => ({ updateElementsById })
  );

  const [replacement, setReplacement] = useState(null);

  const hasReplacement = Boolean(replacement);

  const {
    id,
    lastUpdatedBy = '',
    opacity,
    type,
    isBackground,
    isHidden,
    overlay,
    border = {},
    flip,
  } = element;

  const replacementElement = hasReplacement
    ? {
        ...element,
        type: replacement.resource.type,
        resource: replacement.resource,
        scale: replacement.scale,
        focalX: replacement.focalX,
        focalY: replacement.focalY,
        // Okay, this is a bit weird, but... the flip and overlay properties are taken from the dragged image
        // if the drop-target is the background element, but from the original drop-target image
        // itself if the drop-target is a regular element.
        //
        // @see compare with similar logic in `combineElements`
        flip: isBackground ? replacement.flip : flip,
        overlay: isBackground ? replacement.overlay : overlay,
      }
    : null;

  const { Display } = getDefinitionForType(type);
  const { Display: Replacement } =
    getDefinitionForType(replacement?.resource.type) || {};

  const wrapperRef = useRef(null);

  const box = getBox(element);

  useTransformHandler(id, (transform) => {
    const target = wrapperRef.current;
    if (transform === null) {
      target.style.transform = '';
      target.style.width = '';
      target.style.height = '';
    } else {
      const { translate, rotate, resize, dropTargets } = transform;
      target.style.transform = `translate(${translate?.[0]}px, ${translate?.[1]}px) rotate(${rotate}deg)`;
      if (resize && resize[0] !== 0 && resize[1] !== 0) {
        target.style.width = `${resize[0]}px`;
        target.style.height = `${resize[1]}px`;
      }
      if (dropTargets?.hover !== undefined) {
        target.style.opacity = dropTargets.hover ? 0 : 1;
      }
      if (dropTargets?.replacement !== undefined) {
        setReplacement(dropTargets.replacement || null);
      }
    }
  });

  const bgOverlayRef = useRef(null);
  useColorTransformHandler({
    id,
    targetRef: bgOverlayRef,
    resetOnNullTransform: false,
  });

  const handleTextDimensionsUpdated = useCallback(({ height, width }) => {
    updateElementsById({
      elementIds: [id],
      properties: (currentProperties) => ({
        ...currentProperties,
        height,
        width,
        lastUpdatedBy: 'user',
      }),
    });
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <Wrapper
      ref={wrapperRef}
      data-element-id={id}
      isBackground={element.isBackground}
      previewMode={previewMode}
      {...box}
    >
      <AnimationWrapper id={id} isAnimatable={isAnimatable}>
        <WithMask
          element={element}
          fill
          box={box}
          style={{
            opacity: typeof opacity !== 'undefined' ? opacity / 100 : null,
            ...(shouldDisplayBorder(element)
              ? getBorderPositionCSS({
                  ...getResponsiveBorder(border, previewMode, dataToEditorX),
                  width: `${box.width}px`,
                  height: `${box.height}px`,
                })
              : null),
          }}
          previewMode={previewMode}
        >
          <Display
            element={element}
            previewMode={previewMode}
            box={box}
            handleDimensionsUpdated={
              elementIs.text(element) ? handleTextDimensionsUpdated : undefined
            }
          />
        </WithMask>
        {!previewMode && (
          <ReplacementContainer hasReplacement={hasReplacement}>
            {replacementElement && (
              <WithMask
                element={replacementElement}
                fill
                box={box}
                style={{
                  opacity: opacity ? opacity / 100 : null,
                }}
                previewMode={previewMode}
              >
                <Replacement element={replacementElement} box={box} />
              </WithMask>
            )}
          </ReplacementContainer>
        )}
        {isBackground && overlay && !hasReplacement && (
          <BackgroundOverlay
            ref={bgOverlayRef}
            style={generatePatternStyles(overlay)}
          />
        )}
      </AnimationWrapper>
    </Wrapper>
  );
}

DisplayElement.propTypes = {
  previewMode: PropTypes.bool,
  element: StoryPropTypes.element.isRequired,
  isAnimatable: PropTypes.bool,
};

// Don't rerender the display element needlessly (e.g. element selection change)
// if the element or other props haven't changed.
export default memo(DisplayElement);
