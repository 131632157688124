/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const ASPECT_RATIO_PARAM = 'aspectRatio';
const PAGE_RATIO_PARAM = 'pageRatio';
const PAGE_WIDTH_PARAM = 'pageWidth';
const PAGE_HEIGHT_PARAM = 'pageHeight';

const getParam = (name) => {
  const paramString = new URLSearchParams(window.location.search).get(name);

  if (paramString) {
    const parsed = parseFloat(paramString);

    if (!isNaN(parsed)) {
      return parsed;
    }
  }
};

// If we bring back safe areas, we might need to update the offsetPageWithSafeZone function
export const USE_SAFE_AREA = false;

export const PAGE_WITH_SAFE_AREA_RATIO = getParam(PAGE_RATIO_PARAM) || 2 / 3;
export const PAGE_WIDTH = getParam(PAGE_WIDTH_PARAM) || 412;
export const SAFE_AREA_HEIGHT = getParam(PAGE_HEIGHT_PARAM) || 618;

export const FULLBLEED_RATIO = getParam(ASPECT_RATIO_PARAM) || 9 / 16;
export const FULLBLEED_HEIGHT = PAGE_WIDTH / FULLBLEED_RATIO;

export const PAGE_HEIGHT = USE_SAFE_AREA ? SAFE_AREA_HEIGHT : FULLBLEED_HEIGHT;

export const AMP_DANGER_ZONE_HEIGHT = (FULLBLEED_HEIGHT - SAFE_AREA_HEIGHT) / 2;
export const DANGER_ZONE_HEIGHT = USE_SAFE_AREA ? AMP_DANGER_ZONE_HEIGHT : 0;
export const PAGE_RATIO = USE_SAFE_AREA
  ? PAGE_WITH_SAFE_AREA_RATIO
  : FULLBLEED_RATIO;

// Default 1em value for font size.
export const DEFAULT_EM = 618 * 0.02186;

// Default device pixel ratio.
export const DEFAULT_DPR = 0.5;

// Default size of inserted assets, as a percentage of the available space.
export const DEFAULT_ASSET_PAGE_RATIO = 0.8;
