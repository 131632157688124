/**
 * External dependencies
 */
import { Resource, TrimData } from '@web-stories-wp/media';

export enum QueueItemState {
  CANCELLED = 'CANCELLED',
  MUTING = 'MUTING',
  MUTED = 'MUTED',
  PENDING = 'PENDING',
  TRANSCODING = 'TRANSCODING',
  TRANSCODED = 'TRANSCODED',
  TRIMMING = 'TRIMMING',
  TRIMMED = 'TRIMMED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
}

type QueueItem = {
  id: string;
  additionalData: {
    is_muted?: boolean;
    media_source?: 'gif-conversion' | 'video-optimization';
    meta?: {
      web_stories_trim_data: TrimData;
    };
  };
  file: File;
  isBuffer: boolean;
  isMuting?: boolean;
  isTranscoding?: boolean;
  isTrimming?: boolean;
  state: QueueItemState;
  resource: Resource;
  onUploadStart: () => void;
  onUploadProgress: () => void;
  onUploadError: () => void;
  onUploadSuccess: () => void;
  posterFile: File | null;
  progress: number;
  muteVideo: boolean;
  trimData: TrimData;
};

export type QueueState = { queue: QueueItem[] };

export type BasicAction<ExtraProps = {}> = {
  payload: ExtraProps & {
    id: string;
  };
};

export type AddItemAction = {
  payload: {
    additionalData: unknown;
    file: File;
    isBuffer: boolean;
    muteVideo: boolean;
    onUploadStart: () => void;
    onUploadProgress: () => void;
    onUploadError: () => void;
    onUploadSuccess: () => void;
    posterFile: File;
    resource: Resource;
    trimData: TrimData;
  };
};

export type FinishUploadingAction = BasicAction<{ resource: Resource }>;

export type FinishTranscodingAction = BasicAction<{ file: File }>;

export type UpdateProgressAction = BasicAction<{ progress: number }>;

export type FinishMutingAction = BasicAction<{ file: File }>;

export type FinishTrimmingAction = BasicAction<{ file: File }>;

export type ReplacePlaceholderAction = BasicAction<{
  posterFile: File;
  resource: Resource;
}>;

export type Actions = {
  addItem: (props: AddItemAction['payload']) => void;
  removeItem: (props: BasicAction['payload']) => void;
  // Uploading state
  cancelUploading: (props: BasicAction['payload']) => void;
  finishUploading: (props: FinishUploadingAction['payload']) => void;
  startUploading: (props: BasicAction['payload']) => void;
  updateProgress: (props: UpdateProgressAction['payload']) => void;
  // Transcoding state
  startTranscoding: (props: BasicAction['payload']) => void;
  finishTranscoding: (props: FinishTranscodingAction['payload']) => void;
  // Muting state
  startMuting: (props: BasicAction['payload']) => void;
  finishMuting: (props: FinishMutingAction['payload']) => void;
  // Trimming state
  startTrimming: (props: BasicAction['payload']) => void;
  finishTrimming: (props: FinishTrimmingAction['payload']) => void;
  // Placeholder
  replacePlaceholderResource: (
    props: ReplacePlaceholderAction['payload']
  ) => void;
};
