/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import styled from 'styled-components';

/**
 * Internal dependencies
 */
import StickerElement from './stickerElement';
import StoryPropTypes from '../../types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.borders.radius.small};
  background-color: ${({ theme }) => theme.colors.opacity.black10};
`;

const style = {
  display: 'block',
  height: 20,
  width: 'auto',
};

function StickerLayerContent({ element }) {
  return (
    <Container>
      <StickerElement style={style} element={element} isIcon />
    </Container>
  );
}

StickerLayerContent.propTypes = {
  element: StoryPropTypes.elements.sticker.isRequired,
};

export default StickerLayerContent;
