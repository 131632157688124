import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useCallback, useState, useRef, useEffect } from 'react';
import VideoProps from '../propsTypes/video';
import ExternalMediaVideoPlayer from '../components/externalMediaVideoPlayer';
import StyledMediaRowContainer from '../htmlElements/StyledVideoRowContainer';
import MediaRowElement from '../htmlElements/MediaRowElement';
import MediaContainer from '../htmlElements/MediaContainer';
import {
  AssetMetadata,
  AssetMetadataAuthor,
  AssetMetadataSubtitle,
  AssetMetadataTitle,
} from '../htmlElements/AssetMetadataElements';
import getReadableDateString from '../helpers/getReadableDataString';
import { useExternalMedia } from '@src/web-stories-wp/story-editor/src/app/externalMedia';
import { useResizeEffect } from '@src/web-stories-wp/react/src';
import ExternalVideoThumbnail from '../components/externalVideoThumbnail';
import { ContentType } from '../../../../../../app/media';
import MediaEditMenu from '../../common/mediaEditMenu';
import InnerElement from '../../common/innerElement';
import DeleteDialog from '../../local/deleteDialog';
import { mapExternalMediaTo3p } from '../../common/mediaDataMapping';

const AssetMetadataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function GeneralVideoRowContainer({
  isEditMenuExpanded,
  video,
  onRowClicked,
  lastElementRef,
  listContainerRef,
  handleOnDelete,
  handleOnEditInAzzuu,
  setExpandedMenuId,
}) {
  const type = ContentType.VIDEO;
  const [active, setActive] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const {
    actions: { setVideoAsInPreview },
    state: {
      preview: { videoInPreviewId },
    },
  } = useExternalMedia();

  const { creator, capturedDate, description } = video.metadata;

  const elementRef = useRef(null);
  const thumbnailRef = useRef(null);
  const rowRef = useRef(null);
  const [videoPlayerContainerWidth, setVideoPlayerContainerWidth] = useState(0);

  const makeActive = useCallback(() => setActive(true), []);
  const makeInactive = useCallback(() => setActive(false), []);

  const isPreview = video.id === videoInPreviewId;

  const onPlayClick = useCallback(() => {
    setVideoAsInPreview(video);
  }, [video, setVideoAsInPreview]);

  const handleSetContainerWidth = useCallback(() => {
    const width = elementRef.current?.getBoundingClientRect().width;
    setVideoPlayerContainerWidth(width ?? 0);
  }, [elementRef, setVideoAsInPreview]);

  useEffect(() => handleSetContainerWidth, [isPreview]);

  useResizeEffect(elementRef, handleSetContainerWidth, [
    handleSetContainerWidth,
  ]);

  const onPreviewVisible = useCallback(() => {
    setTimeout(() => {
      const rowBox = rowRef.current?.getBoundingClientRect();
      const containerBox = listContainerRef.current?.getBoundingClientRect();

      const isFullyVisible =
        rowBox?.top > containerBox.top && rowBox.bottom < containerBox.bottom;

      if (isFullyVisible) {
        return;
      }
      const scrollBlock = rowBox.top < containerBox.top ? 'start' : 'end';
      rowRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: scrollBlock,
      });
    }, 3);
  }, []);

  const handleCloseEditMenu = useCallback(() => {
    setExpandedMenuId(null);
  }, [setExpandedMenuId]);

  const handleOpenEditMenu = useCallback(() => {
    setExpandedMenuId(video.id);
  }, [setExpandedMenuId, video.id]);

  const handleDeleteMedia = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, [setIsDeleteDialogOpen]);

  const resource = mapExternalMediaTo3p(video);

  const innerElementProps = {
    active,
    alt: description,
    mediaElement: thumbnailRef,
    onClick: () => {},
    onDimensionsUpdate: (dimensions) => {},
    resource,
    showVideoDetail: false,
    src: video.playcardSmallUrl ?? video.thumbnailUrl,
    type,
  };

  return (
    <StyledMediaRowContainer ref={lastElementRef}>
      <MediaRowElement
        onClick={onRowClicked}
        isPreview={isPreview}
        ref={rowRef}
      >
        <MediaContainer
          ref={elementRef}
          onPointerEnter={makeActive}
          onFocus={makeActive}
          onPointerLeave={makeInactive}
          onBlur={makeInactive}
        >
          {!isPreview ? (
            <ExternalVideoThumbnail video={video} onPlayClick={onPlayClick}>
              <InnerElement {...innerElementProps} isMuted />
            </ExternalVideoThumbnail>
          ) : (
            <ExternalMediaVideoPlayer
              containerWidth={videoPlayerContainerWidth}
              innerElementProps={innerElementProps}
              onPreviewVisible={onPreviewVisible}
              providerType=""
              video={video}
            />
          )}
        </MediaContainer>
        <AssetMetadata>
          <AssetMetadataWrapper>
            <AssetMetadataTitle>{video.title}</AssetMetadataTitle>
            <AssetMetadataAuthor>{creator}</AssetMetadataAuthor>
            {(capturedDate || video.creationTime) && (
              <AssetMetadataSubtitle>
                {getReadableDateString(capturedDate || video.creationTime)}
              </AssetMetadataSubtitle>
            )}
          </AssetMetadataWrapper>
          {(handleOnDelete || handleOnEditInAzzuu) && (
            <MediaEditMenu
              isOpen={isEditMenuExpanded}
              isTransparent
              handleClose={handleCloseEditMenu}
              handleOpen={handleOpenEditMenu}
              handleOnDelete={handleOnDelete ? handleDeleteMedia : undefined}
              handleOnEditInAzzuu={handleOnEditInAzzuu}
            />
          )}
          {handleOnDelete && (
            <DeleteDialog
              isOpen={isDeleteDialogOpen}
              mediaId={video.id}
              type={type}
              onClose={() => setIsDeleteDialogOpen(false)}
              handleOnDelete={handleOnDelete}
            />
          )}
        </AssetMetadata>
      </MediaRowElement>
    </StyledMediaRowContainer>
  );
}

GeneralVideoRowContainer.propTypes = {
  onRowClicked: PropTypes.func,
  video: VideoProps,
};

export default GeneralVideoRowContainer;
