/**
 * External dependencies
 */
import { __ } from '@web-stories-wp/i18n';

/**
 * Internal dependencies
 */

import { TEXT_SHADOW_STYLE } from '../../../../constants';

export const MIN_MAX = {
  BLUR: {
    MIN: 0,
    MAX: 100,
  },
  COORDINATES: {
    MIN: -500,
    MAX: 500,
  },
};

export const SHADOW_OPTIONS = [
  { id: TEXT_SHADOW_STYLE.NONE, label: __('None', 'web-stories') },
  {
    id: TEXT_SHADOW_STYLE.DROP_SHADOW,
    label: __('Drop Shadow', 'web-stories'),
  },
];
