export const map3pToExternalMedia = (asset) => {
  return {
    ...asset,
    id: asset.id,
    externalId: asset.id,
    creationTime: asset.creationDate,
    displayDuration: asset.lengthFormatted?.replaceAll(' ', ''),
    metadata: {
      filetype: asset.mimeType,
      title: asset.title,
      height: asset.height,
      width: asset.width,
    },
    playcardSmallUrl:
      asset.type === 'video'
        ? asset.poster
        : asset.sizes?.web_stories_thumbnail?.source_url || '',
    playcardUrl: asset.poster,
    ratio: asset.width / asset.height,
    title: asset.title,
    thumbnailUrl:
      asset.type === 'video'
        ? asset.poster
        : asset.sizes?.web_stories_thumbnail?.source_url || '',
    url: asset.src,
    videoUrl: asset.type === 'video' ? asset.src : '',
  };
};

export const mapExternalMediaTo3p = (asset) => {
  const formatNumber = (number) => {
    if (typeof number === 'number') {
      return number;
    }

    if (typeof number === 'string') {
      return isNaN(parseInt(number)) ? 0 : parseInt(number);
    }

    return 0;
  };

  return {
    ...asset,
    alt: asset.title ?? asset.metadata?.title,
    height: formatNumber(asset.metadata?.height),
    lengthFormatted: asset.displayDuration || '',
    id: asset.id,
    mimeType: asset.metadata?.filetype,
    poster: asset.playcardUrl,
    sizes: {},
    src: asset.url,
    title: asset.title ?? asset.metadata?.title,
    type: asset.metadata?.filetype.split('/')[0],
    width: formatNumber(asset.metadata?.width),
  };
};
