/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import {
  useEffect,
  useCallback,
  useMemo,
  useReduction,
} from '@web-stories-wp/react';
import {
  trackError,
  trackEvent,
  getTimeTracker,
} from '@web-stories-wp/tracking';
import {
  createBlob,
  getFileName,
  getImageDimensions,
  isAnimatedGif,
} from '@web-stories-wp/media';

/**
 * Internal dependencies
 */
import { useUploader } from '../../../uploader';
import { noop } from '../../../../utils/noop';
import useUploadVideoFrame from '../useUploadVideoFrame';
import useFFmpeg from '../useFFmpeg';
import getResourceFromLocalFile from '../getResourceFromLocalFile';
import { reducer } from './reducer';
import { QueueItemState, type Actions, type QueueState } from './types';
import preloadVideoMetadata from '../../../../../../media/src/preloadVideoMetadata';

const initialState: QueueState = {
  queue: [],
};

function useMediaUploadQueue() {
  const {
    actions: { uploadBuffer, uploadFile },
  } = useUploader();
  const {
    isTranscodingEnabled,
    canTranscodeFile,
    transcodeVideo,
    stripAudioFromVideo,
    getFirstFrameOfVideo,
    convertGifToVideo,
    trimVideo,
  } = useFFmpeg();

  const [_state, actions] = useReduction(initialState, reducer);
  const state = _state as QueueState;
  const { uploadVideoPoster } = useUploadVideoFrame({
    updateMediaElement: noop,
  });
  const {
    startUploading,
    finishUploading,
    cancelUploading,
    startTranscoding,
    startMuting,
    startTrimming,
    finishTranscoding,
    finishMuting,
    finishTrimming,
    replacePlaceholderResource,
    updateProgress,
  } = actions as Actions;

  // Try to update placeholder resources for freshly transcoded file if still missing.
  useEffect(() => {
    async function updateItems() {
      await Promise.all(
        state.queue.map(async (item) => {
          const { id, file, state: itemState, resource } = item;
          if (
            itemState !== QueueItemState.TRANSCODED ||
            !resource.isPlaceholder ||
            resource.poster
          ) {
            return;
          }

          try {
            const { resource: newResource, posterFile } =
              await getResourceFromLocalFile(file);

            replacePlaceholderResource({
              id,
              resource: newResource,
              posterFile,
            });
          } catch {
            // Not interested in errors here.
          }
        })
      );
    }

    updateItems();
  }, [state.queue, replacePlaceholderResource]);

  // Try to get dimensions and poster for placeholder resources.
  // This way we can show something more meaningful to the user before transcoding has finished.
  useEffect(() => {
    async function updateItems() {
      await Promise.all(
        state.queue.map(async (item) => {
          const { id, file, state: itemState, resource } = item;
          if (QueueItemState.PENDING !== itemState || !resource.isPlaceholder) {
            return;
          }

          if (!isTranscodingEnabled || !canTranscodeFile(file)) {
            return;
          }

          try {
            const videoFrame = await getFirstFrameOfVideo(file);
            const poster = createBlob(videoFrame);
            const { width, height } = await getImageDimensions(poster);
            const newResource = {
              ...resource,
              poster,
              width,
              height,
            };
            replacePlaceholderResource({
              id,
              resource: newResource,
              posterFile: videoFrame,
            });
          } catch (error) {
            console.error('getFirstFrameOfVideoFailed', error);
          }
        })
      );
    }

    updateItems();
  }, [
    state.queue,
    isTranscodingEnabled,
    canTranscodeFile,
    getFirstFrameOfVideo,
    replacePlaceholderResource,
  ]);

  const processPoster = useCallback(
    async ({
      newResource,
      posterFileName,
      newPosterFile,
      resource,
      id,
      updateProgress,
    }) => {
      try {
        const { poster, posterId } = await uploadVideoPoster(
          newResource.id,
          posterFileName,
          newPosterFile,
          updateProgress
        );

        let newResourceWithPoster = {
          ...newResource,
          poster: poster || newResource.poster || resource.poster,
          posterId,
        };

        if (resource.mimeType === 'image/gif') {
          newResourceWithPoster = {
            ...newResourceWithPoster,
            output: {
              ...newResourceWithPoster.output,
              poster: poster || newResource.poster || resource.poster,
            },
          };
        }

        finishUploading({
          id,
          resource: newResourceWithPoster,
        });
      } catch (error) {
        console.error('processPosterFailed', error);
        finishUploading({
          id,
          resource: newResource,
        });
      }
    },
    [finishUploading, uploadVideoPoster]
  );

  // Upload files to server, optionally first transcoding them.
  useEffect(() => {
    async function uploadItems() {
      await Promise.all(
        /**
         * Uploads a single pending item.
         *
         * @param {Object} item Queue item.
         * @param {File} item.file File object.
         * @return {Promise<void>}
         */
        state.queue.map(async (item) => {
          const {
            id,
            isBuffer,
            file,
            state: itemState,
            resource,
            additionalData = {},
            posterFile,
            muteVideo,
            trimData,
          } = item;

          if (QueueItemState.PENDING !== itemState) {
            return;
          }

          const trackTiming = getTimeTracker('load_upload_media');
          let progress = 10; // Initialize with non-0 value so loading bar can start showing

          const updateProgressCallback = (
            uploadProgress,
            maxRemainingProgress
          ) => {
            const remainingProgress = maxRemainingProgress - progress;
            const relativeProgress =
              progress + uploadProgress * (remainingProgress / 100);
            progress = relativeProgress;

            updateProgress({ id, progress });
          };

          if (isBuffer) {
            try {
              startUploading({ id });
              updateProgress({ id, progress });

              const newResourceWithAssetUrl = await uploadBuffer(
                resource,
                (progress) => updateProgressCallback(progress, 100)
              );

              finishUploading({
                id,
                resource: newResourceWithAssetUrl,
              });
            } catch (error) {
              // Cancel uploading if there were any errors.
              cancelUploading({ id });

              trackError('upload_media', error?.message);
            } finally {
              trackTiming();
            }

            return;
          }

          const posterFileName = getFileName(file) + '-poster.jpeg';

          let newResource;
          let newFile = file;
          let newPosterFile = posterFile;

          if (file.type.includes('video')) {
            try {
              await preloadVideoMetadata(URL.createObjectURL(file));
            } catch (error) {
              cancelUploading({ id });
              console.error(error);

              return;
            }
          }

          // Convert animated GIFs to videos if possible.
          if (
            isTranscodingEnabled &&
            resource.mimeType === 'image/gif' &&
            isAnimatedGif(await file.arrayBuffer())
          ) {
            startTranscoding({ id });
            updateProgress({ id, progress });

            try {
              newFile = await convertGifToVideo(file);
              finishTranscoding({ id, file: newFile });
              additionalData.media_source = 'gif-conversion';
              additionalData.is_muted = true;

              progress += 10;
              updateProgress({ id, progress });
            } catch (error) {
              // Cancel uploading if there were any errors.
              cancelUploading({ id });

              trackError('upload_media', error?.message);

              return;
            }

            try {
              newPosterFile = await getFirstFrameOfVideo(newFile);

              progress += 10;
              updateProgress({ id, progress });
            } catch (error) {
              // Do nothing here.
            }
          }

          // Transcode/Optimize videos before upload.
          // TODO: Only transcode & optimize video if needed (criteria TBD).
          // Probably need to use FFmpeg first to get more information (dimensions, fps, etc.)
          if (isTranscodingEnabled && canTranscodeFile(file)) {
            if (trimData) {
              startTrimming({ id });
              updateProgress({ id, progress });

              try {
                newFile = await trimVideo(file, trimData.start, trimData.end);
                finishTrimming({ id, file: newFile });
                additionalData.meta = {
                  web_stories_trim_data: trimData,
                };

                progress += 5;
                updateProgress({ id, progress });
              } catch (error) {
                // Cancel uploading if there were any errors.
                cancelUploading({ id });

                trackError('upload_media', error?.message);

                return;
              }
            } else if (muteVideo) {
              startMuting({ id });
              updateProgress({ id, progress });

              try {
                newFile = await stripAudioFromVideo(file);
                finishMuting({ id, file: newFile });
                additionalData.is_muted = true;

                progress += 5;
                updateProgress({ id, progress });
              } catch (error) {
                // Cancel uploading if there were any errors.
                cancelUploading({ id });

                trackError('upload_media', error?.message);

                return;
              }
            } else {
              startTranscoding({ id });
              updateProgress({ id, progress });

              try {
                newFile = await transcodeVideo(file);
                finishTranscoding({ id, file: newFile });
                additionalData.media_source = 'video-optimization';

                progress += 5;
                updateProgress({ id, progress });
              } catch (error) {
                // Cancel uploading if there were any errors.
                cancelUploading({ id });

                trackError('upload_media', error?.message);

                return;
              }
            }
          }

          startUploading({ id });
          updateProgress({ id, progress });

          trackEvent('upload_media', {
            file_size: newFile.size,
            file_type: newFile.type,
          });

          try {
            // The newly uploaded file won't have a poster yet.
            // However, we'll likely still have one on file.
            // Add it back so we're never without one.
            // The final poster will be uploaded later by uploadVideoPoster().
            newResource = await uploadFile(
              newFile,
              additionalData,
              (progress) => updateProgressCallback(progress, 85) // Leave 15% for poster processing
            );
          } catch (error) {
            // Cancel uploading if there were any errors.
            cancelUploading({ id });
            trackError('upload_media', error?.message, true /** fatal */);
          } finally {
            trackTiming();
          }

          if (newResource?.id) {
            await processPoster({
              newResource,
              posterFileName,
              newPosterFile,
              resource,
              id,
              updateProgress: (progress) =>
                updateProgressCallback(progress, 95), // Leave 5% for the poster preloading
            });
          }

          progress = 100;
          updateProgress({ id, progress });
        })
      );
    }

    uploadItems();
  }, [
    state.queue,
    cancelUploading,
    uploadBuffer,
    uploadFile,
    startUploading,
    finishUploading,
    processPoster,
    startTranscoding,
    finishTranscoding,
    isTranscodingEnabled,
    getFirstFrameOfVideo,
    canTranscodeFile,
    transcodeVideo,
    stripAudioFromVideo,
    convertGifToVideo,
    startMuting,
    finishMuting,
    trimVideo,
    startTrimming,
    finishTrimming,
    updateProgress,
  ]);

  return useMemo(() => {
    let progressForAllItems = 100;
    const itemsInProgress = state.queue.filter((item) => item.progress < 100);
    const totalItemsInProgress = itemsInProgress.length;

    if (totalItemsInProgress > 0) {
      const totalProgress = itemsInProgress.reduce((acc, item) => {
        if (isNaN(item.progress)) {
          return acc;
        }

        return acc + item.progress;
      }, 0);

      progressForAllItems = totalProgress / totalItemsInProgress;
    }

    return {
      state: {
        progress: state.queue.filter(
          (item) =>
            ![
              QueueItemState.UPLOADED,
              QueueItemState.CANCELLED,
              QueueItemState.PENDING,
            ].includes(item.state)
        ),
        progressForAllItems,
        pending: state.queue.filter(
          (item) => item.state === QueueItemState.PENDING
        ),
        uploaded: state.queue.filter(
          (item) => item.state === QueueItemState.UPLOADED
        ),
        failures: state.queue.filter(
          (item) => item.state === QueueItemState.CANCELLED
        ),
        isUploading: state.queue.some(
          (item) =>
            ![
              QueueItemState.UPLOADED,
              QueueItemState.CANCELLED,
              QueueItemState.PENDING,
            ].includes(item.state)
        ),
        isTranscoding: state.queue.some(
          (item) => item.state === QueueItemState.TRANSCODING
        ),
        isMuting: state.queue.some(
          (item) => item.state === QueueItemState.MUTING
        ),
        isTrimming: state.queue.some(
          (item) => item.state === QueueItemState.TRIMMING
        ),
      },
      actions: {
        addItem: actions.addItem,
        removeItem: actions.removeItem,
      },
    };
  }, [state, actions]);
}

export default useMediaUploadQueue;
