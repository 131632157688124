/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import styled from 'styled-components';
import { Fragment, memo } from '@web-stories-wp/react';

/**
 * Internal dependencies
 */
import { ReorderableSeparator, ReorderableItem } from '../../../reorderable';
import { useStory } from '../../../../app';
import { LAYER_HEIGHT } from './constants';
import ElementLayer from './elementLayer';
import type { Element } from '@src/web-stories-wp/story-editor/src/elements/types';

const LayerSeparator = styled(ReorderableSeparator)`
  height: ${LAYER_HEIGHT}px;
  margin: -${LAYER_HEIGHT / 2}px 0;
  padding: ${LAYER_HEIGHT / 2}px 0;
`;

export const ReorderableElementLayer = memo(function ReorderableLayer({
  id,
  position,
  nestedOffset,
  nestedOffsetCalcFunc,
  handleStartReordering,
}: {
  id: string;
  nestedOffset: boolean;
  nestedOffsetCalcFunc: (evt) => number;
  position: number;
  handleStartReordering: (element: Element) => void;
}) {
  const { element, groups } = useStory(({ state }) => ({
    element: state.currentPage?.elements.find((el) => el.id === id),
    groups: state.currentPage?.groups || {},
  }));

  const group = groups[element.groupId];

  return element ? (
    <Fragment key={id}>
      {group?.isCollapsed ? null : (
        <LayerSeparator
          groupId={element.groupId}
          nestedOffset={Boolean(nestedOffset)}
          nestedOffsetCalcFunc={nestedOffsetCalcFunc}
          position={position + 1}
          isNested={element.groupId}
        />
      )}
      <ReorderableItem
        position={position}
        onStartReordering={handleStartReordering(element)}
        disabled={element.isBackground}
      >
        <ElementLayer layer={element} />
      </ReorderableItem>
    </Fragment>
  ) : null;
});
