import React, { useRef } from 'react';
import './TemplatesListByFolder.css';
import TemplateList from '@src/web-stories-wp/story-editor/src/components/library/panes/pageTemplates/templateList';
import styled from 'styled-components';
import { virtualPaneContainer } from '@src/web-stories-wp/story-editor/src/components/library/panes/shared/virtualizedPanelGrid';
import PropTypes from 'prop-types';
import { useStudioContext } from '@src/hooks/studioContext';
import { AssetTypes } from '@src/hooks/studioContext/CMSParams';

const PageTemplatesParentContainer = styled.div`
  ${virtualPaneContainer};
  margin-top: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const TemplatesListByFolder = ({
  templates,
  loadTemplates,
  shouldDisplayNoTemplateFoundMessage,
}) => {
  const listContainerRef = useRef();
  const { assetType, contentBankAssetType } = useStudioContext();
  let finalAssetType = contentBankAssetType || assetType;

  let containerHeight = 280;
  let height = 280;

  if (
    finalAssetType === AssetTypes.StoryIcon ||
    finalAssetType === AssetTypes.Square
  ) {
    containerHeight = height = 158;
  } else if (
    finalAssetType === AssetTypes.StoryThumbnail ||
    finalAssetType === AssetTypes.ClipThumbnail ||
    finalAssetType === AssetTypes.Print
  ) {
    containerHeight = height = 237;
  } else if (finalAssetType === AssetTypes.Landscape) {
    containerHeight = height = 89;
  } else if (finalAssetType === AssetTypes.Portrait) {
    containerHeight = height = 198;
  }

  return (
    <div className="templatesListByFolder">
      {shouldDisplayNoTemplateFoundMessage && (
        <div className="templatesListByFolder__noResults">No results found</div>
      )}

      <PageTemplatesParentContainer ref={listContainerRef}>
        {listContainerRef.current && (
          <TemplateList
            pageSize={{
              width: 158,
              containerHeight: containerHeight,
              height: height,
            }}
            parentRef={listContainerRef}
            pages={templates}
            fetchTemplates={loadTemplates}
          />
        )}
      </PageTemplatesParentContainer>
    </div>
  );
};

TemplatesListByFolder.propTyes = {
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadTemplates: PropTypes.func.isRequired,
  shouldDisplayNoTemplateFoundMessage: PropTypes.bool.isRequired,
};

export default TemplatesListByFolder;
