/*
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from '@web-stories-wp/react';
import styled from 'styled-components';
import React, { useRef } from 'react';
import { __ } from '@web-stories-wp/i18n';

/**
 * Internal dependencies
 */
import { Pane } from '../shared';
import { useAPI } from '../../../../app/api';
import paneId from './paneId';
import {
  MediaGalleryLoadingPill,
  SearchInputContainer,
} from '../media/common/styles';
import { SearchInput } from '../../common';
import { Text, THEME_CONSTANTS } from '@web-stories-wp/design-system';
import TemplatesListByFolder from '@components/templatesListByFolder/TemplatesListByFolder.jsx';
import { useStudioContext } from '@hooks/studioContext';
import OptionsList from '@src/components/optionsList/optionsList.jsx';
import OptionsListBackButton from '../../../../../../../components/optionsList/optionsListBackButton/optionsListBackButton';

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.fg.secondary};
`;

export const StyledPane = styled(Pane)`
  height: 100%;
  padding: 0;
  padding-top: 12px;
  overflow: hidden;
`;

export const PaneInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DEFAULT_PAGE_NUMBER = 1;
const POTENTIALLY_ALL_FOLDERS = 999999;
const NO_FOLDER_SELECTED = 'NO_FOLDER_SELECTED';

function PageTemplatesWithFoldersPane(props) {
  const {
    actions: {
      getTemplatesFolders,
      getCustomPageTemplates: getTemplatesByFolder,
    },
  } = useAPI();

  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isTemplatesRequestPending, setIsTemplatesRequestPending] =
    useState(false);
  const [chosenFolder, setChosenFolder] = useState(NO_FOLDER_SELECTED);
  const [searchTemplateQuery, setSearchTemplateQuery] = useState('');
  const [searchFolderQuery, setSearchFolderQuery] = useState('');
  const [nextTemplatesToFetch, setNextTemplatesToFetch] = useState(1);
  const [foldersList, setFoldersList] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);
  const [onlyAllTemplatesFolder, setOnlyAllTemplatesFolder] = useState(false);

  const studioContext = useStudioContext();
  const { assetType, contentBankAssetType } = studioContext;

  const isFolderSelected = chosenFolder !== NO_FOLDER_SELECTED;
  const displayOneFolderOnly = !searchFolderQuery && foldersList?.length === 0;

  const controller = useRef(null);
  const searchRef = useRef();

  const cancelFetch = useCallback(() => {
    if (controller && controller.current) {
      controller.current.abort();
    }
  }, []);

  const loadFoldersList = useCallback(
    (searchText = '', callback = () => {}) => {
      setIsRequestPending(true);
      getTemplatesFolders({
        pageNumber: DEFAULT_PAGE_NUMBER,
        pageSize: POTENTIALLY_ALL_FOLDERS,
        searchText,
      })
        .then(({ folders }) => {
          const allFolders = folders
            .filter((folder) => !!folder)
            .map((folder) => ({ key: folder, label: folder }));
          setFoldersList(allFolders);
          callback(allFolders);
        })
        .catch(console.error)
        .finally(() => {
          setIsRequestPending(false);
        });
    },
    []
  );

  const onFoldersListSearch = useCallback((searchFolderName) => {
    loadFoldersList(searchFolderName);
    setSearchFolderQuery(searchFolderName);
  }, []);

  const loadTemplates = useCallback(
    (searchText) => {
      const isSearchTextPassed = searchText || searchText === '';
      const newController = new AbortController();
      controller.current = newController;

      if (isTemplatesRequestPending && !isSearchTextPassed) return;
      if (isSearchTextPassed) {
        setNextTemplatesToFetch(DEFAULT_PAGE_NUMBER);
        setSearchTemplateQuery(searchText);
      }
      setIsTemplatesRequestPending(true);
      const pageNumber = isSearchTextPassed
        ? DEFAULT_PAGE_NUMBER
        : +nextTemplatesToFetch;
      getTemplatesByFolder(
        pageNumber,
        16,
        chosenFolder === NO_FOLDER_SELECTED ? '' : chosenFolder,
        isSearchTextPassed ? searchText : searchTemplateQuery,
        controller.signal,
        contentBankAssetType || assetType
      )
        .then(({ templates, hasMore }) => {
          setTemplatesList((state) => {
            const currentTemplates = state || [];
            const savedTemplatesIds = currentTemplates.map((x) => x.templateId);
            const result = [];

            if (searchText) {
              return templates;
            }

            for (const template of templates) {
              if (!savedTemplatesIds.includes(template.templateId)) {
                result.push({ ...template, id: template.templateId });
              }
            }

            return [...currentTemplates, ...result];
          });

          if (!hasMore) {
            setNextTemplatesToFetch(false);
          } else {
            setNextTemplatesToFetch(
              isSearchTextPassed
                ? DEFAULT_PAGE_NUMBER + 1
                : nextTemplatesToFetch + 1
            );
          }

          setIsTemplatesRequestPending(false);
        })
        .catch(() => {
          setIsTemplatesRequestPending(false);
          setNextTemplatesToFetch(false);
        })
        .finally(() => setIsTemplatesRequestPending(false));
    },
    [
      chosenFolder,
      nextTemplatesToFetch,
      getTemplatesByFolder,
      isTemplatesRequestPending,
      setNextTemplatesToFetch,
      setIsTemplatesRequestPending,
      setTemplatesList,
      templatesList,
      setSearchTemplateQuery,
      searchTemplateQuery,
      controller,
    ]
  );

  const fetchTemplates = useCallback(() => {
    if (!nextTemplatesToFetch || nextTemplatesToFetch < 2) {
      return;
    }
    loadTemplates();
  }, [nextTemplatesToFetch, loadTemplates]);

  const goBackToAllFolders = () => {
    setChosenFolder(NO_FOLDER_SELECTED);
    setTemplatesList([]);
    setNextTemplatesToFetch(1);
    setSearchTemplateQuery('');
    setSearchFolderQuery('');
  };

  const shouldDisplayNoFolderFoundMessage =
    !!searchFolderQuery && !isRequestPending && foldersList?.length === 0;

  // 1. Load all folders when opening templates
  // 1.1 Decide if display all templates directly or not
  useEffect(() => {
    loadFoldersList('', (folders) => {
      const oneFolder = folders.length === 0 && !searchFolderQuery;
      if (oneFolder) {
        setOnlyAllTemplatesFolder(true);
        setChosenFolder('');
      }
    });
  }, []);

  // 2. Load templates when opening some folder
  // 2.1. Load folders when closing particular folder
  useEffect(() => {
    searchRef.current?.setValue('');

    if (chosenFolder !== NO_FOLDER_SELECTED) {
      loadTemplates();
    } else {
      loadFoldersList();
    }
  }, [chosenFolder]);

  const onSearch = useCallback(
    (searchText = '') => {
      cancelFetch();
      loadTemplates(searchText);
    },
    [cancelFetch, loadTemplates]
  );

  const displayTemplates = useMemo(
    () => isFolderSelected || !!searchTemplateQuery,
    [isFolderSelected, searchTemplateQuery]
  );

  const displayBackButton = useMemo(
    () =>
      isFolderSelected && !displayOneFolderOnly && templatesList.length >= 0,
    [isFolderSelected, displayOneFolderOnly, templatesList.length]
  );

  return (
    <StyledPane id={paneId} {...props}>
      <PaneInner>
        {displayBackButton && (
          <OptionsListBackButton onClick={goBackToAllFolders}>
            {!!chosenFolder && isFolderSelected
              ? chosenFolder
              : 'All Templates'}
          </OptionsListBackButton>
        )}
        <SearchInputContainer marginTop={12}>
          <SearchInput
            incremental
            initialValue=""
            onSearch={onSearch}
            placeholder={__('Search', 'web-stories')}
            ref={searchRef}
          />
        </SearchInputContainer>
        <>
          {!onlyAllTemplatesFolder && !displayTemplates && (
            <OptionsList
              setChosenOption={setChosenFolder}
              options={foldersList}
              isRequestPending={isRequestPending}
              shouldDisplayNoOptionFoundMessage={
                shouldDisplayNoFolderFoundMessage
              }
              shouldDisplayOptionsList={!!foldersList?.length}
              customAllOptionName="All Templates"
            />
          )}
          {displayTemplates && (
            <TemplatesListByFolder
              templates={templatesList}
              loadTemplates={fetchTemplates}
              shouldDisplayNoTemplateFoundMessage={
                !isTemplatesRequestPending && templatesList.length === 0
              }
            />
          )}
        </>
        {isRequestPending ||
          (isTemplatesRequestPending && (
            <MediaGalleryLoadingPill data-testid={'loading-pill'}>
              <StyledText
                forwardedAs="span"
                size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}
              >
                {__('Loading…', 'web-stories')}
              </StyledText>
            </MediaGalleryLoadingPill>
          ))}
      </PaneInner>
    </StyledPane>
  );
}

export default PageTemplatesWithFoldersPane;
